import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Headline from '../Headline/Headline';

import { postRequest, apiParse } from '../../utils/Common';
import config from '../../config';
const env = process.env.NODE_ENV || 'development';

export default class RegistrationSuccess extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	componentDidMount() {
		window.scrollTo(0, 0);

		const { businessRef, businessName, surname, forename, eMail, phoneNumber, } = this.props;

		const URI = `${config[env].URL}/completed`
		const options = {
			businessRef: businessRef,
			businessName: businessName,
			surname: surname,
			forename: forename,
			contactEmail: eMail,
			contactPhone: phoneNumber
		};
		// const credentials = 'include';
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then(response => response)
			.catch(error => error);
	}

	handleCloseBrowserTab = () => {
		this.props.closeBrowserTab();
	}

	renderAddress = addressObj => {
		return Object.keys(addressObj).map((key, index) => {
			if (addressObj[key]) {
				return <span key={index}>{addressObj[key]}<br /></span>;
			}
		});
	};

	render() {
		const {
			componentType,
			className,
			businessName,
			surname,
			forename,
			departmentName,
			payByInvoice,
			registeredAddress
		} = this.props;

		const Element = componentType;
		const classNames = className;

		// Pay by Invoice view
		if (payByInvoice) {
			return (
				<Element className={classNames}>
					<Headline
						componentType="h2"
						type="section"
					>
						Registration successful
					</Headline>
					<div className="terms_overlay">
						<p className="terms">
							Thank you {forename} for registering to use Spotlite.
						</p>
						<p className="terms">
							A text message has been sent with a link to download the Spotlite App.
						</p>
						<p className="terms">
							In the meantime, we will email a copy of your invoice. Please check your email’s Junk or Spam Folder.
						</p>
						<p className="terms">
							Once we have received your payment, sign-in using the details you have provided us today, and you’re good to go.
						</p>
						<p className="terms">
							Please feel free to contact us at any time at: support@cdd.services.
						</p>
						<p className="terms">
							Thank you once again for your business and we look forward to working with you.
						</p>
						<p className="terms">
							<b>Spotlite Support Team</b>
						</p>
					</div>
					<br /> <br />
					<fieldset className="form__fieldset">
						<input
							className="button button--wide button--negative"
							type="submit"
							name="Quit"
							id="Quit"
							value="Close Window"
							onClick={this.handleCloseBrowserTab}
						/>
					</fieldset>

				</Element>
			)
		}

		// Standard payment view
		return (
			<Element className={classNames}>
				<Headline
					componentType="h2"
					type="section"
				>
					Registration successful
				</Headline>

				<p className="terms">
					Congratulations {forename}, you’re all set to start using Spotlite.
				</p>
				<p className="terms">
					We have sent a text message to you so you can Sign-In to the Client Portal and send your first invite.  Sign-in using the email address and the password you entered earlier.  You will need to enter your ICO Registration Number to fully activate your account.
				</p>
				<p className="terms">
					In the meantime, we will email a copy of your invoice/ receipt. Please check your email’s Junk or Spam Folder.   We will also send a hard copy to your Company’s Registered Address for your files.
				</p>
				<p className="terms">
					Please feel free to contact us at any time at: support@cdd.services.
				</p>
				<p className="terms">
					Thank you once again for your business and we look forward to working with you.
				</p>
				<p className="terms">
					<b>Spotlite Support Team</b>
				</p>
				<br /><br />
				<fieldset className="form__fieldset">
					<input
						className="button button--wide button--negative"
						type="submit"
						name="Quit"
						id="Quit"
						value="Close Window"
						onClick={this.handleCloseBrowserTab}
					/>
				</fieldset>
			</Element>
		);
	}
}
