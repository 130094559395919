import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Phone from 'react-phone-number-input';
import Headline from '../Headline/Headline';

class PinNotReceived extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
        const {
            componentType,
            className
        } = this.props;

        const {
			errors
        } = this.props;

		const Element = componentType;
        const classNames = className;

        if (this.props.resendVerificationType === "EMAIL") {
        	return (
				<Element className={classNames}>
					<Headline
						componentType="h2"
						type="section"
					>
						Email Address Verification
					</Headline>

					<Headline
						componentType="h4"
						type="page"
					>
						It may take a couple of minutes for you to receive your code. Check to see if it’s been sent to your spam folder. If it still hasn’t been received, please confirm your email address and request a new verification code.
					</Headline>

					<form
						name="confirmEmail"
						id="confirmEmail"
						className="form"
						onSubmit={this.props.onHandleSubmit}
					>
						<fieldset className="form__fieldset">
							<label className="form__label" htmlFor="eMail">Email Address</label>
							<input
								className={"form__input" + (errors.eMail ? " form__input--error" : "")}
								type="email"
								id="email"
								name="eMail"
								placeholder="Enter your Email address"
								value={this.props.eMail}
								onChange={this.props.onChange}
								onBlur={this.props.onEmailBlur}
							/>
						</fieldset>
						<fieldset className="form__fieldset">
							<div className="form__message form__message--error">
								{this.props.error}
							</div>
						</fieldset>

						<fieldset className="form__fieldset">
							<input
								className="button button--wide"
								type="submit"
								disabled={!this.props.onCanSubmit()}
								value="Request a new code"
							/>
						</fieldset>
					</form>
				</Element>
			)
		} else {
        	return (
				<Element className={classNames}>
					<Headline
						componentType="h2"
						type="section"
					>
						Phone Number Verification
					</Headline>

					<Headline
						componentType="h4"
						type="page"
					>
						It may take a couple of minutes for you to receive your code. If it still hasn’t been received, please confirm your phone number and request a new verification code.
					</Headline>

					<form
						name="confirmPhone"
						id="confirmPhone"
						className="form"
						onSubmit={this.props.onHandleSubmit}
					>
						<fieldset className="form__fieldset">
							<label className="form__label" htmlFor="phoneNumber">Phone Number</label>
							<Phone
								className={"form__phone" + (errors.phoneNumber ? " form__input--error" : "")}
								type="tel"
								id="phoneNumber"
								name="phoneNumber"
								country="GB"
								placeholder="Enter your phone number"
								value={this.props.phoneNumber}
								onChange={this.props.setPhoneNumber}
								onBlur={this.props.onPhoneNoBlur}
							/>
						</fieldset>
						<fieldset className="form__fieldset">
							<div className="form__message form__message--error">
								{this.props.error}
							</div>
						</fieldset>

						<fieldset className="form__fieldset">
							<input
								className="button button--wide"
								type="submit"
								disabled={!this.props.onCanSubmit()}
								value="Request a new code"
							/>
						</fieldset>
					</form>
				</Element>
			)
		}
    }
}

export default PinNotReceived;
