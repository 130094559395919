import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Headline from '../Headline/Headline';

import { postRequest, apiParse } from '../../utils/Common';
import config from '../../config';
const env = process.env.NODE_ENV || 'development';

export default class RegistrationFail extends Component {
    static propTypes = {
        componentType: PropTypes.string
    };

    static defaultProps = {
        componentType: 'div'
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        const { businessRef, businessName, surname, forename, eMail, phoneNumber, error } = this.props;

        const URI = `${config[env].URL}/failed`;
        const options = {
            businessRef: businessRef,
            businessName: businessName,
            surname: surname,
            forename: forename,
            contactEmail: eMail,
            contactPhone: phoneNumber,
            error: error
        };
        // const credentials = 'include';
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        postRequest(URI, options, credentials, headers)
            .then(apiParse)
            .then(response => response)
            .catch(error => error);
    }

    handleCloseBrowserTab = () => {
        this.props.closeBrowserTab();
    }

    render() {
        const {
            componentType,
            className
        } = this.props;

        const Element = componentType;
        const classNames = className;

        return (
            <Element className={classNames}>
                <Headline
                    componentType="h2"
                    type="section"
                >
                    Registration Unsuccessful
                </Headline>
                <div className="terms_overlay">
                    <p className="terms">
                        Thank you {this.props.forename} for registering with us.
                    </p>
                    <p className="terms" >
                        We are very sorry that an error has occurred whilst completing the registration process.
                    </p>
                    <p className="terms">
                        One of our Customer Services Representatives will be in contact with you to resolve the issue as soon as possible.
                    </p>
                    <p className="terms">
                        If you wish to contact us then you can reach us at: <a href="mailto:support@cdd.services">support@cdd.services</a>
                    </p>
                    <p className="terms">
                        Our opening hours are 8:00am to 18:00pm Monday to Friday.
                    </p>

                    <p className="terms form__message--error">
                        <br />
                        Details of the Error::: {this.props.error}
                        <br />
                    </p>

                    <br />
                    <p className="terms">
                        <b>Spotlite Support Team</b>
                    </p>
                    <br />
                    <br />
                </div>

                <fieldset className="form__fieldset">
                    <input
                        className="button button--wide button--negative"
                        type="submit"
                        name="Quit"
                        id="Quit"
                        value="Close Window"
                        onClick={this.handleCloseBrowserTab}
                    />
                </fieldset>
            </Element>
        );
    }
}