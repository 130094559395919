import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddressList from '../AddressList/AddressList';
//import AddressCard from '../AddressCard/AddressCard';
//import AddressSearch from '../AddressSearch/AddressSearch';
import InputAddrSearchTerm from '../InputAddrSearchTerm/InputAddrSearchTerm';

import Headline from '../Headline/Headline';
import config from '../../config';
import { getRequest, apiParse } from '../../utils/Common.js';

const env = process.env.NODE_ENV || 'development';

export default class BusinessDepartmentAndAddress extends Component {
    static propTypes = {
        componentType: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        componentType: 'div'
    };

    constructor(props) {
        super(props);

        this.state = {
            addrSearchTerm: '',
            addrSearchResults: {
                count: 0,
                results: []
            },
            addressLink: '',
            department: '',
            error: null,
            errors: {
                department: true
            },
            workInProgress: false,
            addressSearching: false,
            contactAddr1: this.props.contactAddr1,
            contactAddr2: this.props.contactAddr2,
            contactAddr3: this.props.contactAddr3,
            isEdited: false,
        };
        this.addressSearchTimer = null;

        this.onChangeEvt = this.onChangeEvt.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

	// PMD 24/01/24 Lifecycle not supported in React 18
    // componentWillUpdate(nextProps, nextState) {
    // UNSAFE_componentWillUpdate(nextProps, nextState) {

    //     if (nextState.addrSearchTerm && this.state.addrSearchTerm && nextState.addrSearchTerm !== this.state.addrSearchTerm) {

    //         clearTimeout(this.addressSearchTimer);

    //         this.addressSearchTimer = setTimeout(() => {
    //             this.loadData(nextState.addrSearchTerm);
    //         }, 1000);
    //     }
    //     if (nextState.addressLink && nextState.addressLink !== this.state.addressLink) {
    //         this.loadAddress(nextState.addressLink);
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.addrSearchTerm && prevState.addrSearchTerm && this.state.addrSearchTerm !== prevState.addrSearchTerm) {
    
            clearTimeout(this.addressSearchTimer);
    
            console.log(this.state.addrSearchTerm, prevState.addrSearchTerm);

            this.addressSearchTimer = setTimeout(() => {
                this.loadData(this.state.addrSearchTerm);
            }, 1000);
        }
        if (this.state.addressLink && this.state.addressLink !== prevState.addressLink) {
            this.loadAddress(this.state.addressLink);
        }
    }

    resetAddressState() {

        this.setState({
            ...this.state,
            isEdited: !this.props.useRegisteredAddress, 
            addrSearchTerm: '',
            addrSearchResults: {
                ...this.state.addrSearchResults,
                count: 0,
                results: []
            },
            addressLink: ''
        });
    }

    loadData(searchTerm) {
        const URI = `${config[env].URL}/find-address?searchTerm=${encodeURIComponent(searchTerm)}`;
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(response => {
                this.setState({
                    addrSearchResults: response,
                    addressSearching: false
                });
            })
            .catch(err => err.message || err);
    }

    loadAddress(addressLink) {
        const URI = `${config[env].URL}/select-address?link=${addressLink}`;
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(response => {
                this.props.setAddress(response.address);
                this.setState({
                    contactAddr1: response.address[0].addressLine1,
                    contactAddr2: response.address[1].addressLine2,
                    contactAddr3: response.address[3].addressLine3,
                });

                this.resetAddressState();
            })
            .catch(err => err.message || err);
    }

    onChangeEvt(event) {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        //	this.props.setError(null);

        if (targetName === 'addrSearchTerm') {
            this.props.resetAddrState();

            if (targetValue.length === 0) {
                this.resetAddressState();
            } else {
                this.setState({
                    addressSearching: true
                })
            }

        }

        this.setState({
            [targetName]: targetValue
        })
    }

    handleAddressChange() {
        this.props.setDefaultAddress(this.props.regAddress);
    }

    hasBusinessRegistered(businessRef, businessName, department, e) {
        const URI = `${config[env].URL}/business-account?companyNumber=${businessRef}&departmentName=${department}&businessName=${businessName}`;
        // const credentials = 'include';
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        this.setState({
            workInProgress: true
        });

        return getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(data => {
                this.setState({
                    error: null,
                    workInProgress: false
                });
                this.props.updateBusinessRef(businessRef, businessName, department, this.state.isEdited, e);
            })
            .catch(err => this.setState({
                error: err.message ? err.message : err,
                workInProgress: false
            }));
    }

    onChangeEvent = event => {
        const targetName = event.target.name;
        const value = event.target.value;
        const error = this.state.error ? null : this.state.error;

        this.setState({
            [targetName]: value,
            error,
            errors: {
                ...this.state.errors,
                [targetName]: error ? true : false
            }
        });

    };

    onCheckboxChange = event => {
        const error = this.state.error ? null : this.state.error;

        this.props.resetAddrState();

        this.props.setUseRegisteredAddress(event.target.checked);

        if (event.target.checked) {
            this.props.setDefaultAddress(this.props.regAddress);
            this.setState({
                contactAddr1: this.props.regAddress[0].addressLine1,
                contactAddr2: this.props.regAddress[1].addressLine2,
                contactAddr3: this.props.regAddress[2].addressLine3,
                error: error
            });
        } else {
            this.setState({
                contactAddr1: '',
                contactAddr2: '',
                contactAddr3: '',
                error: error
            });
        }

    };


    onBlurEvent = event => {
        const name = event.target.name;
        const value = event.target.value;
        let error;

        let isEdited = this.state.isEdited;

        if (name === 'department') {
            if (value.length === 0) {
                error = 'Please enter your Trading Name / Department Name'
            } else {
                error = null;
            }
        }

        if (name === 'contactAddr1') {
            let initialValue = this.props.contactAddr1 ? this.props.contactAddr1 : "";
            if (initialValue === value) {
                isEdited = false;
            } else {
                isEdited = true;
            }

            if (value.length === 0) {
                error = 'Address Line 1 is mandatory'
            } else {
                error = null;
            }

        }

        if (name === 'contactAddr2') {
            let initialValue = this.props.contactAddr2 ? this.props.contactAddr2 : "";
            if (initialValue === value) {
                isEdited = false;
            } else {
                isEdited = true;
            }
        }

        if (name === 'contactAddr3') {
            let initialValue = this.props.contactAddr3 ? this.props.contactAddr3 : "";
            if (initialValue === value) {
                isEdited = false;
            } else {
                isEdited = true;
            }
        }

        this.setState({
            isEdited,
            error,
            errors: {
                ...this.state.errors,
                [name]: error ? true : false
            }
        });
    }

    onCanSubmit() {
        const { error, errors } = this.state;
        const { addressSelected } = this.props;

        return error || Object.values(errors).indexOf(true) > -1 || !addressSelected
            ? false
            : true;
    }

    getButtonText() {
        return this.state.workInProgress ? "Please Wait..." : "Confirm";
    }

    render() {
        const {
            componentType,
            className,
            selectedBusiness
        } = this.props;

        // console.log(this.props)
        // console.log(this.state)

        const { error, department, workInProgress, contactAddr1, contactAddr2, contactAddr3 } = this.state;

        const Element = componentType;
        const classNames = className;

        return (
            <Element className={classNames}>
                <Headline>
                    {/*selectedBusiness.CommercialName*/}
                    {selectedBusiness.name}
                </Headline>
                <Headline>
                    Please enter your Trading Name or Department Name.
                </Headline>

                <form className="form" id="businessDepartmentForm">
                    <fieldset className="form__fieldset">
                        <label className="form__label" htmlFor="department">Trading / Department Name</label>
                        <input
                            className={"form__input"}
                            type="text"
                            id="department"
                            name="department"
                            placeholder="Your Trading / Department Name"
                            value={department}
                            onChange={this.onChangeEvent}
                            onBlur={this.onBlurEvent}
                        />
                    </fieldset>

                    <fieldset className="form__fieldset">
                        <input
                            className="form__input form__input--checkbox"
                            type="checkbox"
                            checked={this.props.useRegisteredAddress}
                            onChange={this.onCheckboxChange}
                        />
                        Use your registered address as your trading address
                    </fieldset>

                    {/* Address Search is intially hidden */}
                    
                    <fieldset className="form__fieldset">
                    {
                        !this.props.useRegisteredAddress 
                        ?   <InputAddrSearchTerm
                                addrSearchTerm={this.state.addrSearchTerm}
                                onChangeEvt={this.onChangeEvt}
                                addressSearching={this.state.addressSearching}
                                labelText={this.props.labelText}
                            /> 
                        :   null 
                    }
                    </fieldset>

                    <fieldset className="form__fieldset">
                    {
                         this.state.addrSearchResults && this.state.addrSearchResults.count > 0
                         ?
                             <AddressList
                                 addrSearchResults={this.state.addrSearchResults}
                                 onChangeEvt={this.onChangeEvt}
                                 className="form__address-list"
                             />
                         : null
                    }
                    </fieldset>
 
                    <fieldset className="form__fieldset">
                        <label className="form__label" htmlFor="addrline1">Address Line 1</label>
                            <input
                                className={"form__input"}
                                type="text"
                                id="addrline1"
                                name="contactAddr1"
                                readOnly={false}
                                // PMD 26/01/24 Use the props value
                                // value={contactAddr1 ? contactAddr1 : ''}
                                value={this.props.contactAddr1 ? this.props.contactAddr1 : ''}
                                onChange={this.onChangeEvent}
                                onBlur={this.onBlurEvent}
                            />
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <label className="form__label" htmlFor="addrline2">Address Line 2</label>
                            <input
                                className="form__input"
                                type="text"
                                id="addrline2"
                                name="contactAddr2"
                                readOnly={false}
                                // PMD 26/01/24 Use the props value
                                // value={contactAddr2 ? contactAddr2 : ''}
                                value={this.props.contactAddr2 ? this.props.contactAddr2 : ''}
                                onChange={this.onChangeEvent}
                                onBlur={this.onBlurEvent}
                            />
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <label className="form__label" htmlFor="addrline3">Address Line 3</label>
                            <input
                                className="form__input"
                                type="text"
                                id="addrline3"
                                name="contactAddr3"
                                readOnly={false}
                                // PMD 26/01/24 Use the props value
                                // value={contactAddr3 ? contactAddr3 : ''}
                                value={this.props.contactAddr3 ? this.props.contactAddr3 : ''}
                                onChange={this.onChangeEvent}
                                onBlur={this.onBlurEvent}
                            />
                        </fieldset>

                    <fieldset className="form__fieldset">
                    {this.props.addressSelected ?
          
                    <div className="form__fieldset">

                        <fieldset className="form__fieldset">
                            <label className="form__label" htmlFor="city">Town / City</label>
                            <input
                                className="form__input form__input--disabled"
                                type="text"
                                id="city"
                                name="contactCity"
                                readOnly={true}
                                value={this.props.contactCity ? this.props.contactCity : ''}
                                disabled="disabled"
                            />
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <label className="form__label" htmlFor="state">State / Province / Region</label>
                            <input
                                className="form__input form__input--disabled"
                                type="text"
                                id="state"
                                name="contactState"
                                readOnly={true}
                                value={this.props.contactState ? this.props.contactState : ''}
                                disabled="disabled"
                            />
                        </fieldset>

                        <fieldset className="form__fieldset">
                            <label className="form__label" htmlFor="state">Zip / Postal Code</label>
                            <input
                                className="form__input form__input--disabled"
                                type="text"
                                id="postcode"
                                name="contactPostCode"
                                readOnly={true}
                                value={this.props.contactPostCode ? this.props.contactPostCode : ''}
                                disabled="disabled"
                            />
                        </fieldset>

                    </div>
                    :   null
                    }
                    </fieldset>

                    <fieldset className="form__fieldset">
                        <div className="form__message form__message--error">
                            {error}
                        </div>
                    </fieldset>
                    <input
                        className="button button--negative"
                        type="button"
                        onClick={this.props.handleBackButton}
                        value="Go Back"
                    />
                    <input
                        className="button"
                        type="button"
                        disabled={workInProgress || !this.onCanSubmit()}
                        value={this.getButtonText()}
                        // onClick={( (e) => this.hasBusinessRegistered(selectedBusiness.BusinessRef, selectedBusiness.Name, department, e) )}
                        onClick={((e) => this.hasBusinessRegistered(selectedBusiness.company_number, selectedBusiness.name, department, e))}
                    />
                </form>
            </Element>
        );
    }
}