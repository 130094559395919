import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BusinessSearchResults from './BusinessSearchResults';
import BusinessSearchMissing from './BusinessSearchMissing';
import BusinessSearchForm from './BusinessSearchForm';
import BusinessSearchConfirm from './BusinessSearchConfirm';
import BusinessDepartmentAndAddress from './BusinessDepartmentAndAddress';
import config from '../../config';
import { getRequest, postRequest, apiParse } from '../../utils/Common.js';

const env = process.env.NODE_ENV || 'development';

class BusinessSearch extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = this.getInitialState();
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	getInitialState = () => {
		return {
			department: '',
			searchResults: null,
			searchValue: '',
			confirmSelectedBusiness: false,
			selectedBusiness: null,
			regAddress: [],
			useRegisteredAddress: false,
			bypass: false,
			companyNotListed: false,
		};
	}

	setUseRegisteredAddress = value => {
		this.setState({ useRegisteredAddress: value });
	}

	setDefaultAddress = address => {

		this.props.setAddress(address);
		//	this.props.setRegisteredAddress(address);
		this.setUseRegisteredAddress(true);
	}

	setUnformattedAddress = (unformattedAddress) => {
		// const companyName = this.state.selectedBusiness.CommercialName;
		const companyName = this.state.selectedBusiness.name;
		const URI = `${config[env].URL}/address-lookup-fail`;
		const credentials = 'omit';
		const options = {
			companyName,
			address: unformattedAddress
		};
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then(result => result)
			.catch(error => error)

		const address = [];
		/*
		address[0] = {addressLine1: unformattedAddress.LocationLine1};
		address[1] = {addressLine2: unformattedAddress.LocationLine2 ? unformattedAddress.LocationLine2 : null};
		address[2] = {addressLine3: unformattedAddress.LocationLine3 ? unformattedAddress.LocationLine3 : null};
		address[3] = {locality: unformattedAddress.LocationLine4 ? unformattedAddress.LocationLine4 : null};
		address[4] = {province: unformattedAddress.LocationLine5 ? unformattedAddress.LocationLine5 : null};
		address[5] = {postalCode: unformattedAddress.Postcode ? unformattedAddress.Postcode : null};
		address[6] = {country: unformattedAddress.LocationLine5 ? unformattedAddress.LocationLine5 : unformattedAddress.LocationLine4 ? unformattedAddress.LocationLine4 : null};
		*/

		address[0] = { addressLine1: unformattedAddress.street_address };
		address[1] = { addressLine2: null };
		address[2] = { addressLine3: null };
		address[3] = { locality: unformattedAddress.locality ? unformattedAddress.locality : null };
		address[4] = { province: unformattedAddress.region ? unformattedAddress.region : null };
		address[5] = { postalCode: unformattedAddress.postal_code ? unformattedAddress.postal_code : null };
		address[6] = { country: unformattedAddress.country ? unformattedAddress.country : null };

		return address;
	}

	handleResolveAddress = (address) => {
		this.props.setAddress(address);
		// this.props.setRegisteredAddress(address);
		this.setUseRegisteredAddress(true);
		this.setState({
			regAddress: address,
			confirmSelectedBusiness: true,
			useRegisteredAddress: true
		});
	}

	updateConfirmBusinessSelection = () => {
		// const addrSearchString = `${this.state.selectedBusiness.BusinessLocation.LocationLine1},${this.state.selectedBusiness.BusinessLocation.Postcode}`;
		// console.log(this.state.selectedBusiness)
		const addrSearchString = `${this.state.selectedBusiness.registered_address.street_address},${this.state.selectedBusiness.registered_address.postal_code}`;
		const searchURI = `${config[env].URL}/find-address?searchTerm=${encodeURIComponent(addrSearchString)}`;
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		// console.log(searchURI, credentials, headers);

		getRequest(searchURI, credentials, headers)
			.then(apiParse)
			.then(results => {

				if (results.count === 0) {
					// Experian Address Search cannnot find an address link
					// HERE we send and email to welcome@spotlite.services to report address not resolved issue for company
					// this.handleResolveAddress(this.setUnformattedAddress(this.state.selectedBusiness.BusinessLocation));
					this.handleResolveAddress(this.setUnformattedAddress(this.state.selectedBusiness.registered_address));
				} else {
					const addressLink = results.results[0].format
					const loadURI = `${config[env].URL}/select-address?link=${addressLink}`;
					getRequest(loadURI, credentials, headers)
						.then(apiParse)
						.then(response => {
							if (response.count === 0) {
								// Experian Address Search unable to resolve address link
								// HERE we send and email to welcome@spotlite.services to report address not resolved issue for company	
								// this.handleResolveAddress(this.setUnformattedAddress(this.state.selectedBusiness.BusinessLocation));
								this.handleResolveAddress(this.setUnformattedAddress(this.state.selectedBusiness.registered_address));
							} else {
								this.handleResolveAddress(response.address);
							}
						})
						.catch(err => {
							// console.log(err.message, err);
						});
				}
			})
			.catch(err => {
				// console.log(err.message, err);
			});
	}

	updateSearchResults = (searchData, searchValue) => {

		this.setState({
			// searchResults: JSON.parse(searchData)
			searchResults: searchData,
			searchValue

		});
	};

	updateSelectedBusiness = businessDetails => {
		businessDetails.registered_address.street_address = businessDetails.registered_address.street_address.replace(/\r?\n|\r/g, ' ');
		this.setState({
			selectedBusiness: businessDetails
		});
	};

	clearSearchResults = ({bypass}) => {

		this.setState({
			searchResults: null,
			searchValue: null,
			companyNotListed: false,
			bypass: bypass,
		})
		if(bypass) {
			this.props.goDirect();
		}
	};

	clearSelectedBusiness = () => {

		this.setState({
			selectedBusiness: null,
			confirmSelectedBusiness: false,
			companyNotListed: false,
			regAddress: []
		});

		this.props.resetAddrState();
		this.props.resetRegisteredAddrState();
	};


	handleBackButton = () => {
		this.setState({
			companyNotListed: false,
		})
	}

	companyNotListed = () => {
		this.setState({
			companyNotListed: true,
		})
	}

	render() {
		const {
			selectedBusiness,
			searchResults,
			searchValue,
			confirmSelectedBusiness,
			useRegisteredAddress,
			bypass,
			companyNotListed,
		} = this.state;

		if (confirmSelectedBusiness) {
			return (
				<BusinessDepartmentAndAddress
					selectedBusiness={selectedBusiness}
					addressSelected={this.props.addressSelected}
					contactAddr1={this.props.contactAddr1}
					contactAddr2={this.props.contactAddr2}
					contactAddr3={this.props.contactAddr3}
					contactCity={this.props.contactCity}
					contactPostCode={this.props.contactPostCode}
					contactState={this.props.contactState}
					regAddress={this.state.regAddress}
					useRegisteredAddress={useRegisteredAddress}
					updateBusinessRef={this.props.updateBusinessRef}
					handleBackButton={this.clearSelectedBusiness}
					resetAddrState={this.props.resetAddrState}
					resetRegisteredAddrState={this.props.resetRegisteredAddrState}
					setAddress={this.props.setAddress}
					setDefaultAddress={this.setDefaultAddress}
					setUseRegisteredAddress={this.setUseRegisteredAddress}
				/>
			);
		} else {

			if (selectedBusiness) {
				return (
					<BusinessSearchConfirm
						selectedBusiness={selectedBusiness}
						updateConfirmBusinessSelection={this.updateConfirmBusinessSelection}
						handleBackButton={this.clearSelectedBusiness}
					/>
				);
			}

			if (companyNotListed) {
				return (
					<BusinessSearchMissing
						handleManualSubmission={this.clearSearchResults}
						updateBusinessRef={this.props.updateBusinessRef}
						goBack={() => this.handleBackButton}
						setAddress={this.props.setAddress}
					/>
				);
			}

			if (searchResults) {
				return (
					<BusinessSearchResults
						searchResults={searchResults}
						searchValue={searchValue}
						updateSelectedBusiness={this.updateSelectedBusiness}
						handleBackButton={() => this.clearSearchResults}
						handleCompanyNotListed={this.companyNotListed}
					/>
				);
			}

			if(bypass){
				return <></>
			}

			return (
				<BusinessSearchForm
					updateSearchResults={this.updateSearchResults}
				/>
			);
		}
	}
}

export default BusinessSearch;