import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import { postRequest, apiParse } from '../../utils/Common.js';
import config from '../../config';

import Headline from '../Headline/Headline';

const env = process.env.NODE_ENV || 'development';

class EnterVerificationCode extends Component {
    static propTypes = {
        componentType: PropTypes.string
    };

    static defaultProps = {
        componentType: 'div'
    };

    constructor(props) {
        super(props);
        this.state = {
            verificationCode: '',
            message: null,
            error: null,
            workInProgress: false
        }
        this.onChange = this.onChange.bind(this);
        this.onCanSubmit = this.onCanSubmit.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.setError = this.setError.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.setVerificationCode = this.setVerificationCode.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onChange(value) {
        let { error, message } = this.state;

        // Clear previous error message or message if user enters a character in field 
        if (error) {
            this.setError(null);
        }
        if (message) {
            this.setMessage(null);
        }
        this.setVerificationCode(value);
    }

    onCanSubmit() {
        let { error } = this.state;

        return error ? false : true;
    }


    onHandleSubmit(event) {
        event.preventDefault();

        const { verificationCode } = this.state;
        if (this.onCanSubmit()) {
            // const URI = `${config[env].URL}/verify-phone`;
            const URI = `${config[env].VERIFYURL}/verify-phone`;

            this.setState({
                workInProgress: true
            });
            const options = {
                sessionId: this.props.sessionId,
                pinCode: verificationCode
            };
            const headers = { 'appkey': process.env.REACT_APP_APP_KEY };
            // const credentials = 'include';
            const credentials = 'omit';

            postRequest(URI, options, credentials, headers)
                .then(apiParse)
                .then(() => this.setState({ workInProgress: false }))
                .catch(error => this.setError(error = error.message || error))
                .then(() => this.props.setPinVerified(true, null))
        }
    }

    setError(error) {
        this.setState({
            error
        })
    }

    setMessage(message) {
        this.setState({
            message
        })
    }

    setVerificationCode(verificationCode) {
        this.setState({
            verificationCode
        })
    }

    getButtonText() {
        return this.state.workInProgress ? "Please Wait..." : "Verify";
    }

    render() {
        const {
            componentType,
            className,
            phoneNumber
        } = this.props;

        const Codeprops = {
            inputStyle: {
                fontFamily: 'monospace',
                margin: '4px',
                MozAppearance: 'textfield',
                width: '55px',
                borderRadius: '5px',
                fontSize: '28px',
                height: '45px',
                paddingLeft: '14px',
                backgroundColor: '#fff',
                color: '#1052a2',
                border: '1px solid #002E3A',
                textAlign: 'center'
            }
        };

        let { error, message } = this.state;

        const Element = componentType;
        const classNames = className;

        return (
            <Element className={classNames}>
                <Headline
                    componentType="h2"
                    type="section"
                >
                    Phone Number Verification
                </Headline>

                <Headline
                    componentType="h4"
                    type="page"
                >
                    We have sent your verification code to <strong>{phoneNumber}</strong>
                    <br />
                    <br />
                    Please enter your code below.
                </Headline>

                {/*
                <ReactCodeInput
                    className="form__pincode"
                    type="number"
                    fields={4}
                    onChange={this.onChange}
                    {...Codeprops}
                />
                */}

                <ReactCodeInput
                    className="form__pincode"
                    type="number"
                    fields={4}
                    pattern="[0-9]*"
                    inputmode="numeric"
                    filterKeyCodes={[189, 190, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90]}
                    onChange={this.onChange}
                    {...Codeprops}
                />

                <fieldset className="form__fieldset">
                    <input
                        className="button"
                        type="submit"
                        value={this.getButtonText()}
                        disabled={!this.onCanSubmit()}
                        onClick={this.onHandleSubmit}
                    />
                </fieldset>

                <fieldset className="form__fieldset">
                    <div className={error ? "form__message--error" : "form__message"}>
                        {error ? error : message ? message : null}
                    </div>
                </fieldset>

                <button
                    className="button button--alternative"
                    type="button"
                    onClick={() => {
                        this.props.handleNotReceivedButton('SMS');
                    }}
                    key="notReceivedButton"
                >
                    Code not received?
                </button>
            </Element>
        );
    }
}

export default EnterVerificationCode;
