import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Headline from '../Headline/Headline';
import Modal from '../Modal/Modal';

export default class CreateAccount extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = {
			liveProvingCode: '',
			liveProvingModalOpen: false,
			codeInvalid: null
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	// Bypass Live Proving
	bypassLiveProvingAndContinue = () => {
		this.props.handleContinue();
	}

	handleSubmit = event => {
		event.preventDefault();

		const correctLiveProvingCode = 'sayfriendandenter';

		if (this.state.liveProvingCode === correctLiveProvingCode) {
			this.handleLiveProvingModalClose(() => {
				this.props.handleContinue();
			});
		} else {
			this.setState({
				codeInvalid: true
			})
		}
	};

	handleCodeChange = event => {
		this.setState({
			liveProvingCode: event.target.value
		})
	};

	handleLiveProvingModalClose = callback => {
		this.setState({
			liveProvingModalOpen: false
		}, () => {
			if (callback) {
				callback();
			}
		});
	};

	handleLiveProvingModalOpen = event => {
		event.preventDefault();

		this.setState({
			liveProvingModalOpen: true
		});
	};

	render() {
		const {
			componentType,
			className
		} = this.props;

		const Element = componentType;
		const classNames = className;

		return (
			<Element className={classNames}>
				<Headline
					componentType="h3"
					type="section"
				>
					Welcome to our Quotation and Registration Portal
				</Headline>

				<div className="terms_overlay">

				<div className="terms">
					<p>
						Enter your details for a no commitment quotation.
					</p>
					<p>
						To start using Spotlite immediately, accept the quotation and pay by card.
					</p>
					<p>
						That’s it - you’re up and running!
					</p>
					{/* <p>
						<strong>Note:</strong> If you are not yet registered with the ICO, you will need enter your ICO Registration Number to fully access your account once open. <a href="https://ico.org.uk/about-the-ico/what-we-do/register-of-fee-payers/"
							target="_blank"
							rel="noopener noreferrer">
							<u><small>What is this ?</small></u></a>
							
					</p> */}
					{/*
					<p>
						You can find full descriptions of our services within our <a href="http://spotlite.services/terms.html"
							target="_blank"
							rel="noopener noreferrer">
							<strong>Terms and Conditions</strong></a>
						.  Please press Continue below.
					</p>
					*/}
					{/*
					<p>
						Please press Continue below.
					</p>
					*/}
				</div>

				</div>

				<a className="button button--negative button--mob-width" href="http://spotlite.services" target="_blank" rel="noopener noreferrer">
					Go Back
				</a>

				<button
					type="button"
					className="button"
					onClick={this.bypassLiveProvingAndContinue}

				// Bypass Live Proving
				// onClick={this.handleLiveProvingModalOpen} 
				>
					Continue
				</button>


				<Modal
					open={this.state.liveProvingModalOpen}
					onClose={this.handleLiveProvingModalClose}
				>
					<Headline componentType="h4">Opening Soon</Headline>
					<p>We are completing our final tests and will be opening for business soon.  Please pop back early September.  Looking forward to seeing you then.</p>
					<p>If you are part of our live proving team you know what to do!</p>

					<form
						name="liveProving"
						id="liveProving"
						className="form"
						onSubmit={this.handleSubmit}
					>
						<fieldset className="form__fieldset">
							<label className="form__label" htmlFor="liveProvingCode">Live Proving code</label>
							<input
								name="liveProvingCode"
								className={"form__input" + (this.state.codeInvalid === true ? " form__input--error" : "")}
								type="password"
								value={this.state.liveProvingCode}
								onChange={this.handleCodeChange}
								placeholder="Enter live proving code"
							/>
						</fieldset>

						<fieldset className="form__fieldset">
							<span />
							<button
								className="button button--negative"
								type="button"
								onClick={() => {
									this.handleLiveProvingModalClose();
								}}
							>
								I’ll come back later
							</button>
							<button
								className="button"
								type="submit"
							>
								Submit
							</button>
						</fieldset>
					</form>
				</Modal>
			</Element>
		);
	}
}