import React, { Component } from 'react';
// import Phone from 'react-phone-number-input';
import Headline from '../Headline/Headline';
import InputAddrSearchTerm from '../InputAddrSearchTerm/InputAddrSearchTerm';
import AddressList from '../AddressList/AddressList';

import './style/BusinessSearchResults.css';

import { validateEmail, validatePhoneNo } from '../../utils/Validation';
import { postRequest, apiParse, getRequest } from '../../utils/Common';

import config from '../../config';
const env = process.env.NODE_ENV || 'development';

export default class BusinessSearchMissing extends Component {

	constructor(props) {
		super(props);
		this.state = {
			companyName: '',
			// name: '',
			// eMail: '',
			// phoneNumber: '',
			// comments: '',
			error: null,
			errors: {
				companyName: true,
				// name: true,
				// eMail: true,
				// phoneNumber: true,
				contactAddr1: true,
				contactAddrPostCode: true
			},
			reported: false,
			addrSearchTerm: '',
            addrSearchResults: {
                count: 0,
                results: []
            },
            addressLink: '',
			addressSearching: false,
			contactAddr1: '',
            contactAddr2: '',
            contactAddr3: '',
			contactAddrLocality: '',
			contactAddrPostCode: '',
			contactAddrCountry: '',
		};
		this.addressSearchTimer = null;
		this.onChangeEvt = this.onChangeEvt.bind(this);
		this.onBlurEvent = this.onBlurEvent.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {

        if (this.state.addrSearchTerm /*&& prevState.addrSearchTerm*/ && this.state.addrSearchTerm !== prevState.addrSearchTerm) {
    
            clearTimeout(this.addressSearchTimer);

            this.addressSearchTimer = setTimeout(() => {
                this.loadData(this.state.addrSearchTerm);
            }, 1000);
        }
        
		if (this.state.addressLink && this.state.addressLink !== prevState.addressLink) {
            this.loadAddress(this.state.addressLink);
        }

    }

	loadAddress(addressLink) {
        const URI = `${config[env].URL}/select-address?link=${addressLink}`;
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(response => {
                
				this.resetAddressState();

                this.setState({
                    contactAddr1: response.address[0].addressLine1,
                    contactAddr2: response.address[1].addressLine2,
                    contactAddr3: response.address[2].addressLine3,
					contactAddrLocality: response.address[3].locality,
					contactAddrPostCode: response.address[5].postalCode,
					contactAddrCountry: response.address[6].country,
					errors: {
						...this.state.errors,
						contactAddr1: false,
						contactAddrPostCode: false,
					},
                });
            })
            .catch(err => err.message || err);
    }

	loadData(searchTerm) {

        const URI = `${config[env].URL}/find-address?searchTerm=${encodeURIComponent(searchTerm)}`;
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(response => {

                this.setState({
                    addrSearchResults: response,
                    addressSearching: false
                });
            })
            .catch(err => err.message || err);
    }

	onChange = event => {
		let targetName = event.target.name;
		let targetValue = event.target.value;

		// Clear previous error message if user enters a character in field
		let error = this.state.error ? null : this.state.error;

		this.updateState(error, targetName, targetValue);
	};

	updateState = (error, targetName, targetValue) => {
		this.setState({
			...this.state,
			error: error,
			[targetName]: targetValue,
			addressSearching: targetName === 'addrSearchTerm' && targetValue.length > 0 ? true : false,
			errors: {
				...this.state.errors,
				[targetName]: !!error
			},
		});
	};

	/* REMOVE PHONE NUMBER VALIDATION
	setPhoneNumber = phoneNumber => {
		// Clear previous error message if user enters a character in field
		let error = this.state.error ? null : this.state.error;

		this.updateState(error, 'phoneNumber', phoneNumber)
	};
	*/

	onCanSubmit = () => {
		let { error, errors } = this.state;

		return !(error || Object.values(errors).indexOf(true) > -1);
	};

	onEmailBlur = event => {
		const emailAddress = event.target.value;
		const targetName = event.target.name;

		if (emailAddress.length === 0) {
			this.updateState('Your email address is required', targetName, emailAddress);
		} else if ((targetName === 'eMail')) {
			const URI = `${config[env].URL}/email`;
			const options = {
				email: emailAddress,
			};
			const credentials = 'omit';
			const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

			validateEmail(emailAddress)
				.then(() => {
					return postRequest(URI, options, credentials, headers)
						.then(apiParse)
						.then(data => this.updateState(null, targetName, emailAddress))
						.catch(err => { throw err })
				})
				.catch(err => this.updateState(err.message || err, targetName, emailAddress))
		}
	};

	/* REMOVE PHONE NUMBER VALIDATION
	onPhoneNoBlur = event => {
		const phoneNumber = event.target.value || '';
		const targetName = 'phoneNumber';

		const URI = `${config[env].URL}/phone`;
		const options = {
			phone: phoneNumber
		};
		const credentials = 'omit';

		if (phoneNumber.length === 0) {
			this.updateState('Telephone number is required', targetName, phoneNumber);
		} else {
			validatePhoneNo(phoneNumber)
				.then(() => {
					return postRequest(URI, options, credentials)
						.then(apiParse)
						.then(data => this.updateState(null, targetName, phoneNumber))
						.catch(err => { throw err })
				})
				.catch(err => this.updateState(err.message || err, targetName, phoneNumber))
		}
	};
	*/

	handleCompanyMissingSubmit = event => {
		event.preventDefault();

		const {
			companyName,
			// name,
			// eMail,
			// phoneNumber,
			contactAddr1,
			contactAddr2,
			contactAddr3,
			contactAddrCountry,
			contactAddrLocality,
			contactAddrPostCode,
			// comments
		} = this.state;

		if (this.onCanSubmit()) {
			
			/*
			const URI = `${config[env].URL}/missing-company`;
			const options = {
				missingCompanyName: companyName,
				reporterName: name,
				contactEmail: eMail,
				contactPhone: phoneNumber,
				contactAddress: contactAddr1,
				contactPostCode: contactAddrPostCode,
				comments: comments
			};
			*/

			const address = [
				{
					addressLine1: contactAddr1
				},
				{
					addressLine2: contactAddr2
				},
				{
					addressLine3: contactAddr3
				},
				{
					locality: contactAddrLocality
				},
				{
					province: null
				},
				{
					postalCode: contactAddrPostCode
				},
				{
					country: contactAddrCountry
				}	
			]

			this.setState({
				workInProgress: false,
				reported: true
			})

			this.props.setAddress(address)
			this.props.handleManualSubmission({bypass: true});
			this.props.updateBusinessRef(companyName.slice(0, 15), companyName, companyName, true);

			//this.setState({ workInProgress: true });

			//const credentials = 'omit';
			//const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

			/*
			postRequest(URI, options, credentials, headers)
				.then(apiParse)
				.then(() =>
					this.setState({
						workInProgress: false,
						reported: true
					})
				)
				.catch(error =>
					this.setState({
						workInProgress: false,
						error: error.message || error
					})
				)
				*/
		}
	};

	resetAddressState() {

        this.setState({
            ...this.state,
            addrSearchTerm: '',
            addrSearchResults: {
                ...this.state.addrSearchResults,
                count: 0,
                results: []
            },
            addressLink: '',
        });
    }

	onChangeEvt(event) {
        const targetName = event.target.name;
        const targetValue = event.target.value;
		const error = this.state.error ? null : this.state.error;

        if (targetName === 'addrSearchTerm') {
            if (targetValue.length === 0) {
				console.log("Reset address")
                this.resetAddressState();
            } 
			else 
			{
				this.updateState(error, targetName, targetValue);
            }
        } else {
			this.updateState(error, targetName, targetValue);
		}
    }

	onBlurEvent = event => {
		let targetName = event.target.name;
		let targetValue = event.target.value;
		let error;

		if (targetValue.length === 0) {
			switch (targetName) {
				case 'companyName':
					error = 'Your company name is required';
					break;
				case 'name':
					error = 'Your name is required';
					break;
				case 'phoneNumber':
					error = 'Your contact telephone number is required';
					break;
				case 'contactAddr1':
					error = 'Address Line 1 is mandatory';
					break;
				case 'contactAddrPostCode':
					error = 'Postal Code is mandatory';
					break;

				default:
					error = null;
					break;
			}
		} else {
			error = null;
		}

		this.updateState(error, targetName, targetValue);

    }

	render() {
		const {
			errors,
			reported
		} = this.state;

		// if (reported) {
		// 	return (
		// 		<div>
		// 			<Headline componentType="h4">Thank you. We’ll get right back to you.</Headline>
		// 			<button
		// 				className="button button--negative button--wide"
		// 				type="button"
		// 				onClick={() => this.props.handleBackButton({bypass: true})}
		// 			>
		// 				Start a new search
		// 			</button>
		// 		</div>
		// 	)
		// }

		return (
			<div>
				<Headline componentType="h4">Please enter your details below</Headline>

				<form
					name="companyMissing"
					id="companyMissing"
					className="form"
					onSubmit={this.handleCompanyMissingSubmit}
				>
					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="companyName">Trading Name</label>
						<input
							className={"form__input" + (errors.companyName ? " form__input--error" : "")}
							type="text"
							id="companyName"
							name="companyName"
							placeholder="Trading name"
							value={this.state.companyName}
							onChange={this.onChange}
							onBlur={this.onBlurEvent}
						/>
					</fieldset>

					{/* <fieldset className="form__fieldset">
						<label className="form__label" htmlFor="name">Name</label>
						<input
							className={"form__input" + (errors.name ? " form__input--error" : "")}
							type="text"
							id="name"
							name="name"
							placeholder="Your name"
							value={this.state.name}
							onChange={this.onChange}
							onBlur={this.onBlurEvent}
						/>
					</fieldset> */}


					{/* Address search field */}
					<fieldset className="form__fieldset">
                         <InputAddrSearchTerm
                                addrSearchTerm={this.state.addrSearchTerm}
                                onChangeEvt={this.onChangeEvt}
                                addressSearching={this.state.addressSearching}
                                labelText={'Correspondence Address Search'}
                            /> 
                    </fieldset>

					<fieldset className="form__fieldset">
                    {
                         this.state.addrSearchResults && this.state.addrSearchResults.count > 0
                         ?
                             <AddressList
                                 addrSearchResults={this.state.addrSearchResults}
                                 onChangeEvt={this.onChangeEvt}
                                 className="form__address-list"
                             />
                         : null
                    }
                    </fieldset>
 

					<fieldset className="form__fieldset">
                        <label className="form__label" htmlFor="addrline1">Address Line 1</label>
						<input
							className={"form__input" + (errors.contactAddr1 ? " form__input--error" : "")}
							type="text"
							id="addrline1"
							name="contactAddr1"
							readOnly={false}
							value={this.state.contactAddr1}
							onChange={this.onChangeEvt}
							onBlur={this.onBlurEvent}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="addrline2">Address Line 2</label>
						<input
							className="form__input"
							type="text"
							id="addrline2"
							name="contactAddr2"
							readOnly={false}
							value={this.state.contactAddr2}
							onChange={this.onChangeEvt}
							onBlur={this.onBlurEvent}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="addrline3">Address Line 3</label>
						<input
							className="form__input"
							type="text"
							id="addrline3"
							name="contactAddr3"
							readOnly={false}
							value={this.state.contactAddr3}
							onChange={this.onChangeEvt}
							onBlur={this.onBlurEvent}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="city">Town / City</label>
						<input
							className="form__input"
							type="text"
							id="city"
							name="contactAddrLocality"
							readOnly={false}
							value={this.state.contactAddrLocality}
							onChange={this.onChangeEvt}
                            onBlur={this.onBlurEvent}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="state">State / Province / Region</label>
						<input
							className="form__input"
							type="text"
							id="state"
							name="contactAddrCountry"
							readOnly={false}
							value={this.state.contactAddrCountry}
							onChange={this.onChangeEvt}
                            onBlur={this.onBlurEvent}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="postcode">Zip / Postal Code</label>
						<input
							className={"form__input" + (errors.contactAddrPostCode ? " form__input--error" : "")}
							type="text"
							id="postcode"
							name="contactAddrPostCode"
							readOnly={false}
							value={this.state.contactAddrPostCode}
							onChange={this.onChangeEvt}
                            onBlur={this.onBlurEvent}
						/>
					</fieldset>

					{/*
					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="eMail">Email Address</label>
						<input
							className={"form__input" + (errors.eMail ? " form__input--error" : "")}
							type="email"
							id="email"
							name="eMail"
							placeholder="Enter your Email address"
							value={this.state.eMail}
							onChange={this.onChange}
							onBlur={this.onEmailBlur}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="phoneNumber">Phone Number</label>
						<input
							className={"form__input" + (errors.phoneNumber ? " form__input--error" : "")}
							type="tel"
							id="phoneNumber"
							name="phoneNumber"
							placeholder="Enter your phone number"
							value={this.state.phoneNumber}
							onChange={this.onChange}
							onBlur={this.onBlurEvent}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="comments">Comments</label>
						<textarea
							className="form__textarea"
							id="comments"
							name="comments"
							placeholder="Add any optional comments"
							value={this.state.comments}
							onChange={this.onChange}
						/>
					</fieldset>
					*/}
					<fieldset className="form__fieldset">
						<div className="form__message form__message--error">
							{this.state.error}
						</div>
					</fieldset>

					<fieldset className="form__fieldset">
						<input
							className="button button--wide"
							type="submit"
							disabled={!this.onCanSubmit()}
							value="Next"
						/>
					</fieldset>
				</form>

				 <button
					className="button button--alternative"
					type="button"
					onClick={this.props.goBack()}
					key="businessSearchBackButton"
				>
					Go Back
				</button>
			</div>
		);
	}
}
