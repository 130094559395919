import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Headline from '../Headline/Headline';
import BusinessSearchMissing from './BusinessSearchMissing';
import Modal from '../Modal/Modal';

import './style/BusinessSearchResults.css';

class BusinessSearchResults extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = {
			notFoundModalOpen: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleClick = businessDetails => {
		if (businessDetails) {
			this.props.updateSelectedBusiness(businessDetails);
		}
	};

	handleNotFoundModalClose = () => {
		this.setState({
			notFoundModalOpen: false
		});
	};

	handleNotFoundModalOpen = event => {
		event.preventDefault();

		this.props.handleCompanyNotListed();

		// this.setState({
		// 	notFoundModalOpen: true
		// });
	};

	renderResults() {
		/*
		if (this.props.searchResults.SearchResults.length) {

			return this.props.searchResults.SearchResults.map((result, index) => {
				let displayDetails = result.Name;
				displayDetails += result.BusinessLocation && result.BusinessLocation.LocationLine3 ? `, ${result.BusinessLocation.LocationLine3}` : '';
				displayDetails += result.BusinessLocation && result.BusinessLocation.Postcode ? `, ${result.BusinessLocation.Postcode}` : '';
				return (
					<li key={index} className="search-results__result">
						<a
							className="search-results__link"
							onClick={(e) => this.handleClick(result, e)}
						>
							{displayDetails}
						</a>
					</li>
				)
			})
		}
		*/

		let numberFound = this.props.searchResults.companies.length;
		if (numberFound) {

			return this.props.searchResults.companies.map((result, index) => {
				let company = result.company;
				let displayDetails = numberFound > 1 ? `${company.name}, ${company.company_number}` : company.name;
				displayDetails += company.registered_address && company.registered_address.locality ? `, ${company.registered_address.locality}` : '';
				displayDetails += company.registered_address && company.registered_address.postal_code ? `, ${company.registered_address.postal_code}` : '';
				return (
					<li key={index} className="search-results__result">
						<a
							className="search-results__link"
							onClick={(e) => this.handleClick(company, e)}
						>
							{displayDetails}
						</a>
					</li>
				)
			})
		}

		return (
			<li className="search-results__result">
				<a
					className="search-results__link"
					onClick={this.props.handleBackButton}
				>
					{/*Sorry, we can’t find any search results for “{this.props.searchResults.SearchSummary.BusinessName}”. Try a new search!*/}
					Sorry, we can’t find any search results for “{this.props.searchValue}”. Try a new search!
				</a>
			</li>
		)
	}

	// handleBackButton = ({bypass}) => {
	// 	this.props.handleBackButton({bypass: bypass})
	// }

	renderNotFoundButton() {
		return ([
			<button
				className="button button--alternative"
				type="button"
				onClick={this.handleNotFoundModalOpen}
				key="notFoundButton"
			>
				Company not listed?
			</button>
			//,
			// <Modal
			// 	open={this.state.notFoundModalOpen}
			// 	onClose={this.handleNotFoundModalClose}
			// 	key="notFoundModal"
			// >
				// <BusinessSearchMissing
				// 	handleBackButton={this.handleBackButton}
				// />
			// </Modal>
		])
	}

	render() {
		const {
			componentType,
			className,
			searchResults,
			searchValue
		} = this.props;

		const Element = componentType;
		const classNames = className;

		return (
			<Element className={classNames}>
				{/* <Headline>
					Search Results for “{searchValue}” 
				</Headline> */}

				<Headline>
					<p>Please select your company:</p>
				</Headline>

				<div className="section">
					<ul className="search-results">
						{ this.renderResults() }
					</ul>
				</div>

				<button
					className="button button--negative"
					type="button"
					onClick={this.props.handleBackButton({bypass: false})}
				>
					Start a new search
				</button>

				{ this.renderNotFoundButton() }
			</Element>
		);
	}
}

export default BusinessSearchResults;