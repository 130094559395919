import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Headline from '../Headline/Headline';
import RegisterForm from '../RegisterForm/RegisterForm';

class LandingPage extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);

		this.state = {
			addrSearchTerm: '',
			addrSearchResults: {
				count: 0,
				results: []
			},
			addressLink: ''
		};

		this.onCanSubmit = this.onCanSubmit.bind(this);
		this.onPhoneNoChange = this.onPhoneNoChange.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	onPhoneNoChange(phoneNumber) {
		this.props.setPhoneNumber(phoneNumber);
	}

	onCanSubmit() {
		return this.props.onCanSubmit();
	}

	render() {
		const {
			componentType,
			className,
		} = this.props;

		const Element = componentType;
		const classNames = className;

		return (
			<Element className={classNames}>
				{/*
				<Headline
					componentType="h2"
					type="section"
				>
					Please enter your
				</Headline>
				*/}
				<Headline
					componentType="h2"
					type="page"
				>
					Please enter your
				</Headline>

				<Headline
					componentType="h3"
				>
					Contact Details
				</Headline>

				<div className="section">
					
					<p className="terms terms--contact">We use your email address to contact you and, if you accept our Service Quotation, to setup your account. We use your mobile number to send you a message when we need to validate it is you changing things on the account.</p>
				
					<RegisterForm
						surname={this.props.surname}
						middleName={this.props.middleName}
						forename={this.props.forename}
						eMail={this.props.eMail}
						confirmEmail={this.props.confirmEmail}
						// password={this.props.password}
						// passwordConfirm={this.props.passwordConfirm}
						phoneNumber={this.props.phoneNumber}
						error={this.props.error}
						errors={this.props.errors}
						errorList={this.props.errorList}
						workInProgress={this.props.workInProgress}
						onCanSubmit={this.onCanSubmit}
						onChange={this.props.onChange}
						onBlurEvent={this.props.onBlurEvent}
						onPhoneNoChange={this.onPhoneNoChange}
						onPhoneNoBlur={this.props.onPhoneNoBlur}
						onEmailBlur={this.props.onEmailBlur}
						onHandleSubmit={this.props.onHandleSubmit}
						updateErrorState={this.props.updateErrorState}
						getButtonText={this.props.getButtonText}
					/>
				</div>

				<button
					className="button button--alternative"
					type="button"
					onClick={this.props.handleCancelButton}
				>
					Cancel registration
				</button>
			</Element>
		);
	}
}

export default LandingPage;