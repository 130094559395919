import React from 'react';
import Address from '../Address/Address';

const AddressList = (props) => {
	let {addrSearchResults} = props;
	const {className} = props;

	const classNames = `address-list ${(className) ? className : ''}`;
	const resultsLength = addrSearchResults.results.length;
	const placeholder = (resultsLength > 1) ? `${resultsLength} matches. Please type more of the address to refine your results` : '1 match. Please select your address';

	return (
		<select
			className={classNames}
			name="addressLink"
			onChange={props.onChangeEvt}
			size={resultsLength + 1}
			defaultValue=""
		>
			<option value="">
				{placeholder}
			</option>
			{
				addrSearchResults.results.map((address) => {
						return (
							<Address
								key={address.format}
								format={address.format}
								suggestion={address.suggestion}
							/>
						);
					}
				)}
		</select>
	);
}

export default AddressList;