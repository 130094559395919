import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import './style/Modal.css';

export default class Modal extends PureComponent {

	componentDidUpdate(prevProps, prevState) {
		if (this.props.open !== prevProps.open) {
			const modal = ReactDOM.findDOMNode(this.modal);

			if (this.props.open) {
				modal.classList.add('modal--transition');

				setTimeout(() => {
					modal.classList.add('modal--active');
				}, 50)
			} else {
				modal.classList.remove('modal--active');

				setTimeout(() => {
					modal.classList.remove('modal--transition');
				}, 300)
			}
		}
	};

	handleClose = () => {
		const { onClose } = this.props;

		if (onClose) {
			onClose();
		}
	};

	handleOverlayClose = event => {
		if(event.target === event.currentTarget) {
			this.handleClose();
		}
	};

	render() {
		const {
			children
		} = this.props;

		const classNames = `modal`;

		return (
			<div className={classNames} onClick={this.handleOverlayClose} ref={(modal) => { this.modal = modal; }}>
				<div className="modal__content">
					<button
						className="modal__close icon icon--close"
						type="button"
						onClick={this.handleClose}
					>
						Close
					</button>

					{children}
				</div>
			</div>
		);
	}
}
