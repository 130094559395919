import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getRequest, apiParse } from '../../utils/Common.js';
import config from '../../config';

import Headline from '../Headline/Headline';

const env = process.env.NODE_ENV || 'development';

class VerifyEmail extends Component {
    static propTypes = {
        componentType: PropTypes.string
    };

    static defaultProps = {
        componentType: 'div'
    };

    constructor(props) {
        super(props);
        // props will be the email address

        this.state = {
            message: null,
            error: null,
            workInProgress: false
        }

        this.requestEmail = this.requestEmail.bind(this);
        this.onCanSubmit = this.onCanSubmit.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.setError = this.setError.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.getButtonText = this.getButtonText.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const { sessionId, resendVerificationType } = this.props;

        if (!resendVerificationType) {
            // const URI = `${config[env].URL}/send-link?sessionId=${sessionId}`;
            const URI = `${config[env].VERIFYURL}/send-link?sessionId=${sessionId}`;
            // const credentials = 'include';
            const credentials = 'omit';
            const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

            this.setState({
                workInProgress: true
            });

            getRequest(URI, credentials, headers)
                .then(apiParse)
                .then(response => this.setMessage(response.message))
                .catch(error => this.setError(error = error.message || error))
                .then(() => this.setState({ workInProgress: false }))
        }
    }

    requestEmail() {
        /**
         * NB: dont think this function is used : MJ
         */
        const { error, message } = this.state;

        const URI = `${config[env].URL}/resend-link`;
        // const credentials = 'include';
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        if (error) {
            this.setError(null);
        }
        if (message) {
            this.setMessage(null);
        }

        getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(response => this.setMessage(response.message))
            .catch(error => this.setError(error = error.message || error))
    }


    onCanSubmit() {
        let { error } = this.state;

        return error ? false : true;
    }


    onHandleSubmit(event) {
        event.preventDefault();

        const { error, message } = this.state;
        const { sessionId } = this.props;

        if (error) {
            this.setError(null);
        }
        if (message) {
            this.setMessage(null);
        }

        // const URI = `${config[env].URL}/check-verified?sessionId=${sessionId}`;
        const URI = `${config[env].VERIFYURL}/check-verified?sessionId=${sessionId}`;

        this.setState({
            workInProgress: true
        });

        // const credentials = 'include';
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        getRequest(URI, credentials, headers)
            .then(apiParse)
            .then(() => this.props.setEmailVerified(true, null))
            .catch(error => this.setError(error = error.message || error))
            .then(() => this.setState({ workInProgress: false }))
    }

    setError(error) {
        this.setState({
            error
        })
    }

    setMessage(message) {
        this.setState({
            message
        })
    }

    getButtonText() {
        return this.state.workInProgress ? 'Please Wait ...' : 'Continue';
    }

    render() {
        const {
            componentType,
            className,
            eMail
        } = this.props;

        let {
            error,
            message,
            workInProgress,
        } = this.state;

        const Element = componentType;
        const classNames = className;

        return (
            <Element className={classNames}>
                <Headline
                    componentType="h2"
                    type="section"
                >
                    Email Address Verification
                </Headline>

                <Headline
                    componentType="h4"
                    type="page"
                >
                    We have sent a message to your email address <strong>{eMail}</strong>
                    <br />
                    <br />
                    Please open the email and click on the enclosed link. If you haven’t received an email, check your Junk or Trash folders before requesting a new email.
                </Headline>

                <form className="form">
                    <fieldset className="form__fieldset">
                        <input
                            className="button button--wide"
                            type="submit"
                            value={this.getButtonText()}
                            disabled={workInProgress}
                            onClick={this.onHandleSubmit}
                        />
                    </fieldset>

                    <fieldset className="form__fieldset">
                        <div className={error ? "form__message--error" : "form__message"}>
                            {error ? error : message ? message : null}
                        </div>
                    </fieldset>
                </form>

                <button
                    className="button button--alternative"
                    type="button"
                    onClick={() => {
                        this.props.handleNotReceivedButton('EMAIL');
                    }}
                    key="notReceivedButton"
                >
                    Code not received?
                </button>
            </Element>
        );
    }
}

export default VerifyEmail;