import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Headline from '../Headline/Headline';
import Loader from '../Loader/Loader';

import { postRequest, apiParse } from '../../utils/Common';
import config from '../../config';
const env = process.env.NODE_ENV || 'development';

export default class CreateAccount extends Component {
    static propTypes = {
        componentType: PropTypes.string
    };

    static defaultProps = {
        componentType: 'div'
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        const URI = `${config[env].URL}/create-account`;

        // Consolidate items purchased (subscription, credits, addons etc) into one array to pass to the API for processing
        const lineItems = [
            // ...this.props.subscriptionSelected,
            ...this.props.subscriptions,
            ...this.props.services,
            this.props.promoSelected,
            ...this.props.addonSelected
        ].filter(Boolean);

        const options = {
            businessRef: this.props.businessRef,
            businessName: this.props.businessName,
            departmentName: this.props.departmentName,
            contactEmail: this.props.contactEmail,
            forename: this.props.forename,
            middleName: this.props.middleName,
            surname: this.props.surname,
            password: this.props.password,
            contactPhone: this.props.contactPhone,
            promoCode: this.props.promoCode,
            registeredAddr1: this.props.registeredAddr1,
            registeredAddr2: this.props.registeredAddr2,
            registeredAddr3: this.props.registeredAddr3,
            registeredCity: this.props.registeredCity,
            registeredCountry: this.props.registeredCountry,
            registeredPostCode: this.props.registeredPostCode,
            registeredState: this.props.registeredState,
            contactAddr1: this.props.contactAddr1,
            contactAddr2: this.props.contactAddr2,
            contactAddr3: this.props.contactAddr3,
            contactCity: this.props.contactCity,
            contactCountry: this.props.contactCountry,
            contactPostCode: this.props.contactPostCode,
            contactState: this.props.contactState,
            catalogueSelected: this.props.catalogueSelected,
            catalogueName: this.props.catalogueName,
            lineOfBusinessId: this.props.lineOfBusinessId,
            servicePackCode: this.props.servicePackCode,
            servicePackName: this.props.servicePackName,
            totalCredits: this.props.totalCredits,
            totalNet: this.props.totalNet,
            totalTax: this.props.totalTax,
            orderTotal: this.props.orderTotal,
            lineItems: lineItems,
            spotliteStartDate: this.props.spotliteStartDate,
            spotliteEndDate: this.props.spotliteEndDate,
            spotliteDueDate: this.props.spotliteDueDate,
            billingFrequency: this.props.billingFrequency,
            currencyCode: this.props.currencyCode,
            isAddressEditted: this.props.isAddressEditted
        };

        // const credentials = 'include';
        const credentials = 'omit';
        const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

        // console.log(options);

        postRequest(URI, options, credentials, headers)
            .then(apiParse)
            .then(response => this.props.handleAccountCreation({ error: null, invoiceNumber: response.invoiceNumber }))
            .catch(error => this.props.handleAccountCreation({ error: error.message || error, invoiceNumber: null }));
    }

    render() {
        const {
            componentType,
            className
        } = this.props;

        const Element = componentType;
        const classNames = className;

        return (
            <Element className={classNames}>
                <Headline
                    componentType="h2"
                    type="section"
                >
                    Create Account
                </Headline>
                <div className="terms_overlay">
                    <p className="terms">
                        We are currently creating your account, please wait.
                    </p>
                    <p className="terms">
                        If there is a balance to pay you will soon be re-directed to the payment page.
                    </p>
                </div>
                <Loader
                    className=""
                    active={true}
                    activeName="loader-medium--active"
                    loaderName="loader-medium"
                />
            </Element>
        );
    }
}