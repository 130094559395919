import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Headline from '../Headline/Headline';
import AutoModal from '../AutoModal/AutoModal';

import './style/ServicePacks.css';

export default class SerivcePackServices extends Component {

	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'li'
	};

	constructor(props) {
		super(props);
	}


    render() {

		const {
            ServicePackName,
			ServicePackShortDescription,
			CardImageURL,
		} = this.props.service;

        return (
			//no animation for catalogue card because animation class in parent clashes with bootstrap modal 
			<div className="card card--animate_services">	
				
				{/* <div className="overlay">
					<AutoModal icon="info" iconClassName="card_info_icon">
						{this.props.catalogueFeatures.length ? <Headline componentType="h4">{this.props.catalogueName}</Headline> : null}
						{this.renderFeatures(this.props.catalogueFeatures)}
					</AutoModal>
				</div> */}

				{CardImageURL === undefined ? null : <img className="card_image" src={CardImageURL} alt="Avatar" />}

				{/* <img className="card_image" src="https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Avatar" /> */}
			

				<div className="card_container">
                    <h4><b>{ServicePackName}</b></h4> 
                    <p>{ServicePackShortDescription}</p>
				</div> 

                <div className="card_button_container">
					{/* Remove display inline-flex when only showing one button */}
                    {/* {this.renderBackButton()} */}
					{this.renderPackSelectButton(this.props.service)}
                </div>
				
			</div>
		
		);
	
	};

	// renderContents = () => {

    //     return (
    //         <div className="services__wrapper">
    //             <div className="services__content services__content--pack">
    //                 { this.renderServicePacks() }
    //             </div>
    //         </div>
    //     )
    
	// };

    // renderServicePacks = () => {

	// 	return (
	// 		<ul className="services">	

	// 			{this.props.catalogue.ServicePacks.map((pack, index) => {

	// 			return(
				
	// 				<div key={index} className="card card--animate_services">
								
	// 					<img className="card_image" src="https://cddmanagement.services/wp-content/uploads/2020/09/gateway_business.png" alt="Avatar" />
						
	// 					<div className="card_container">
	// 						<h4><b>{pack.ServicePackName}</b></h4> 
	// 						<p>{pack.ServicePackShortDescription}</p>
	// 					</div> 
					
	// 					<div className="card_button_container">
	// 					{this.renderBackButton()}
	// 					{/* {this.renderPriceButton(pack)} */}
	// 					</div>

	// 				</div>);
	// 			})}
    //         </ul>
    //     );
    // };
    
    renderBackButton() {
	
		
		return (
			<button className="card_button"
			type="button"
			onClick={() => this.props.onBackClick()}>
				<span>Back</span>
			</button>
		);
	}

	renderPackSelectButton = pack => {

		//packSelected comes this.state.packSelected
		const {
			packSelected
		} = this.props;

		const selected = (packSelected && packSelected.ServicePackCode === pack.ServicePackCode) ? true : false;
		//const buttonClassNames = selected ? 'button--selected' : '';

		return (
			<button
				className="card_button"
				type="button"
				onClick={() => this.props.onPackSelect(pack)}
				disabled={selected}>
					<span>Prices</span>
			</button>
		)

	};

	handleBackButton = () => {
		this.setState({ expandCatalogue: false });
		this.props.onBackClick();
	};

	renderFeatures = features => {
		if (features.length) {
			return (
				<ul>
					{
						features.map((feature, index) => {
							return (
								<li key={index}>{feature}</li>
							)
						})
					}
				</ul>
			)
		}

		return null;
	};

}