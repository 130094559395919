import React from 'react';
import Loader from '../Loader/Loader';

const InputAddrSearchTerm = props => (

	<div className="form__fieldset">
		<label className="form__label" htmlFor="addrsearchTerm">{ props.labelText ? props.labelText : 'Correspondence Address Search'}</label>
		<input
			className="form__input"
			type="search"
			id="addrsearchTerm"
			name="addrSearchTerm"
			placeholder="Enter Post Code or Street Name"
			value={props.addrSearchTerm}
			onChange={props.onChangeEvt}
		/>
		<Loader
			className="form__input-spinner"
			active={props.addressSearching}
			activeName="loader--active"
			loaderName="loader"
			spinnerName="loader__spinner"
		/>
	</div>
);

export default InputAddrSearchTerm;

/*
const InputAddrSearchTerm = props => (
	<div>
		<fieldset className="form__fieldset">
			<label className="form__label" htmlFor="Correspondence Address Search">Company name</label>
			<input
				className="form__input"
				id="addrsearchTerm"
				name="addrSearchTerm"
				type="text"
				value={props.addrSearchTerm}
				onChange={props.onChangeEvt}
				placeholder="Enter Post Code or Street Name"
			/>
		</fieldset>

		<fieldset className="form__fieldset">
			<input
				className="button button--wide"
				id="addrsearchTerm"
				type="submit"
				name="search"
				value="Search"
				onClick={props.onHandleBlur}
			/>
		</fieldset>
	</div>
);

export default InputAddrSearchTerm;
*/
