import 'babel-polyfill';

import React from 'react';
// PMD 23/01/24 React 18 Upgrade
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import App from './components/App';
import registerServiceWorker from './registerServiceWorker';

import TagManager from 'react-gtm-module';

import config from './config';
const env = process.env.NODE_ENV || 'development';

const tagManagerArgs = {
	gtmId: config[env].GTMID,
	auth: config[env].GTMAUTH,
	preview: config[env].GTMPREVIEW
};

TagManager.initialize(tagManagerArgs);

// PMD 23/01/24 React 18 Upgrade
// ReactDOM.render(<App />, document.getElementById('root'));
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App tab="home" />);

registerServiceWorker();
