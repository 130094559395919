import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Headline from '../Headline/Headline';
import AutoModal from '../AutoModal/AutoModal';

import './style/ServicePacks.css';

export default class ServicePackCatalogue extends Component {

	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'li'
	};

	constructor(props) {
		super(props);

		this.state = {
			expandCatalogue: false,
			hideCatalogue: false
		};
	}

	componentDidUpdate(prevProps) {

		// if (this.props.catalogueSelected !== prevProps.catalogueSelected) {
		// 	if (this.props.catalogueSelected) { // A catalogue is selected
		// 		console.log("Catalogue selected");
		// 		if (this.props.catalogueSelected === this.props.catalogue.CatalogueCode) { // This component is selected
		// 			setTimeout(() => {
		// 				this.setState({
		// 					expandCatalogue: true
		// 				})
		// 			}, 300);
		// 		} else { // Other components
		// 			setTimeout(() => {
		// 				this.setState({
		// 					hideCatalogue: true
		// 				});
		// 			}, 300);
		// 		}
		// 	} else { // No catalogue selected
		// 		if (prevProps.catalogueSelected === this.props.catalogue.CatalogueCode) { // This component was selected
		// 			setTimeout(() => {
		// 				this.setState({
		// 					expandCatalogue: false
		// 				});
		// 			}, 300);
		// 		} else { // Other components
		// 			setTimeout(() => {
		// 				this.setState({
		// 					hideCatalogue: false
		// 				})
		// 			}, 300);
		// 		}
		// 	}
		// }
	}



	// renderSelectedText = (isSelected) => {

	// 	if (isSelected) {
	// 		return 'Selected';
	// 	}

	// 	return 'Select';
	// };

	render() {
		// const {
		// 	componentType,
		// 	children
		// } = this.props;

		// const {
		// 	CatalogueName,
		// 	Features,
		// 	CatalogueShortDescription,
		// 	CatalogueCode
		// } = this.props.catalogue;

		//const classExpanded = (this.state.expandCatalogue) ? 'services__catalogue--expanded' : '';
		//const classHide = (this.state.hideCatalogue) ? 'services__catalogue--hide' : '';

		//const Element = componentType;
		//const classNames = `services__catalogue ${classExpanded} ${classHide}`;

		//console.log(this.props.catalogues);

		return (

 		<ul className="services">
		
			{this.props.catalogues.map((catalogue, index) => {

// card--animate_services
				let className = "card_info_icon";
				if(catalogue.CardImageURL === "" || catalogue.CardImageURL === undefined){
					className = "header_info_icon"; 
				}


				return (
					<div key={index} className="card">
						
						<div className="overlay">
							<AutoModal icon="info" iconClassName={className}>
								{catalogue.Features.length ? <Headline componentType="h4">{catalogue.CatalogueName}</Headline> : null}
								{this.renderFeatures(catalogue.Features)}
							</AutoModal>
						</div>

						{/* <img className="card_image" src="https://cddmanagement.services/wp-content/uploads/2020/09/gateway_business.png" alt="Avatar" /> */}
						
						{ catalogue.CardImageURL !== "" && catalogue.CardImageURL !== undefined ? <img className="card_image" src={catalogue.CardImageURL} alt="Avatar" /> : null }

						{/* <img className="card_image" src={catalogue.CardImageURL !== "" ? catalogue.CardImageURL :
					"https://cddmanagement.services/wp-content/uploads/2020/09/gateway_business.png"} alt="Avatar" /> */}
						
						<div className="card_container">
							<h4><b>{catalogue.CatalogueName}</b></h4> 
							<p>{catalogue.CatalogueShortDescription}</p>
						</div> 
						{this.renderCatalogueButton(catalogue.CatalogueCode, catalogue.CatalogueName)}

					</div>
				
				);
			})}

		</ul>
		);
	};


	renderCatalogueButton(catalogueCode, catalogueName) {
	
		
		return (
			<button className="card_button"
			type="button"
			onClick={() => this.props.onCatalogueSelect(catalogueCode, catalogueName)}>
				<span>Select</span>
			</button>
		);
			// onClick={() => {
			// 	console.log(catalogueCode, catalogueName);

			// 	this.setState({ showServices: true, selectedCatalogue: catalogueCode, expandCatalogue: true });
			// }}>
	}

	renderFeatures = features => {
		if (features.length) {
			return (
				<ul>
					{
						features.map((feature, index) => {
							return (
								<li key={index}>{feature}</li>
							)
						})
					}
				</ul>
			)
		}

		return null;
	};


}
