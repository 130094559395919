import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Logo from '../Logo/Logo';

export default class Header extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'header'
	};

	render() {
		const {
			componentType,
			...others
		} = this.props;
		const Element = componentType;

		return (
            <Element className="header wrapper" role="contentinfo" {...others}>
                <Logo className="header__logo" />
            </Element>
		);
	}
}
