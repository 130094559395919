import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Headline from '../Headline/Headline';
import Modal from '../Modal/Modal';

import './style/ServicePacks.css';

export default class ServicePackItems extends Component {

	static propTypes = {
		componentType: PropTypes.string,
		currency: PropTypes.string,
		locale: PropTypes.string
	};

	constructor(props) {
        super(props);
	
		this.state = {
			modalOpen: false,
			workInProgress: false,
		};
	};

	handleModalClose = () => {
		this.setState({
			modalOpen: false
		});
	};

	handleModalOpen = event => {
		event.preventDefault();

		this.setState({
			modalOpen: true
		});
	};

	renderDiscountItems() {
		return (
			<div>
				<Headline componentType="h3">
					Credit Packs And Discounts
				</Headline>

				<p className="terms">
					Prices quoted excluding VAT
				</p>

				<table className="service-table">
					<tbody>
					{ this.props.paymentMethods.CreditTypes.map((option, index) => {
						return (
							<tr key={index}>
								<td className="service-table__column service-table__column--mobile-break">
									<span className="service-table__cost">{option.Name}</span>
									<span className="service-table__title">{ (option.StandardUnitPriceCredits === 1) ? `${option.StandardUnitPriceCredits} ${this.props.paymentMethods.CreditName}` : `${option.StandardUnitPriceCredits} ${this.props.paymentMethods.CreditNamePlural}` }</span>
								</td>
								<td className="service-table__column service-table__column--mobile-break">
									<span className="service-table__unit">
										{new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: this.props.currency })
										.format(option[this.props.currency].CurrencyCreditConversionRate).replace(/\.00/g,'')} each	
									</span>
								</td>
								<td className="service-table__column service-table__column--medium service-table__column--right service-table__column--mobile-break">
									<span className="service-table__title">
										{new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: this.props.currency })
										.format(option[this.props.currency].PremiumUnitPriceCurrency).replace(/\.00/g,'')}
										</span>
								</td>
							</tr>
						)
					}) }
					</tbody>
				</table>
			</div>
		);
	}

	renderDiscounts() {
		return ([
			<a href="#" onClick={this.handleModalOpen} key="discountModelTag"> <strong><u>here</u></strong>.
			<br />
			<br />
			</a>,
			<Modal
				open={this.state.modalOpen}
				onClose={this.handleModalClose}
				key="discountModalText"
			>
				{this.renderDiscountItems()}
			</Modal>
		]);
	};

	renderIndividualPrice(price) {
		
		const {
			currency,
			locale,
			conversionRate
		} = this.props;

		return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price * conversionRate).replace(/\.00/g,'');
	}

	renderPrice = (price, noOfUnits) => {
		
		const {
			currency,
			locale,
			conversionRate,
		} = this.props;

		if(noOfUnits === 0) {
			return null;
		}
		
		return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price * noOfUnits * conversionRate).replace(/\.00/g,'');
	};

	handleAddOnBackButton = () => {
		//this.props.handleBackButton(false);
		this.props.onServiceBack();
	}

	render() {
		const {
			packSelected,
			paymentMethods,
		} = this.props;


		return (
			
			<div className="card card--credits" ref={(ref) => { this.serviceItemSection = ref; }}>
				{/* <br /> */}

				{/* <img className="card_image" src="https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Avatar" /> */}
			
				<img className="card_image" src={packSelected.CardImageURL} alt="Avatar" />
			
				<div className="credits">

				<span className="servicesBackButton" onClick={() => this.handleAddOnBackButton()}>Back</span>
				<Headline componentType="h3">
					Services Available Within This Pack
				</Headline>
				<br />
				{/* terms -> font */}

				<div className="font">Use the Applicant Calculator below and we’ll work out the discounts for your quote.
				<br /><br />
				You purchase our Services using Credits, which are fully transferable.
				<br /><br />
				The more Credits you buy, the greater the discount. To see the discounts available click {this.renderDiscounts()}.
				</div>
				<br />

				<table className="service-table">
					<thead>
					<tr>
						<td>Service</td>
						<td className="service-table__column service-table__column--mobile-break">No. Of Applicants</td>
						<td className="service-table__column service-table__column--mobile-break">No. Of Credits</td>
						<td className="service-table__column service-table__column--mobile-break">Unit Price</td>
						<td className="service-table__column service-table__column--mobile-break">Price <small>(excl. VAT)</small></td>
					</tr>
					</thead>
					<tbody>

					{ packSelected.Jurisdictions[0].LinesOfBusiness[0].Services.map((service, index) => {
						return (
							<tr key={index}>
								<td>
									<span className="service-table__title">{service.Name}</span>
									<span className="service-table__title"><small><b>{ (service.StandardUnitPriceCredits === 1) ? `${service.StandardUnitPriceCredits} ${paymentMethods.CreditName}` : `${service.StandardUnitPriceCredits} ${this.props.paymentMethods.CreditNamePlural}` }</b></small></span>
									<span className="service-table__description">{service.ServiceShortDescription}</span>
								</td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">				
									<input
										type="number"
										min={0} 
										max={100000}
										value={
											isNaN(service.NumberOfUnits) ? '' 
											: service.NumberOfUnits < 0 ? 0 
											: service.NumberOfUnits === 0 ? ''
											: service.NumberOfUnits > 10000 ? 10000
											: service.NumberOfUnits
										}
										onChange={(evt) => this.props.handleServicePackItemChange(evt, index)}
									/>
								</td>

								{/* Number of Credits */}

								<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__unit">
									{
										isNaN(service.NumberOfUnits) ? ''
										: service.NumberOfUnits < 0 ? 0
										: service.NumberOfUnits === 0 ? ''
										: service.NumberOfUnits > 10000 ? (10000 * service.StandardUnitPriceCredits)
										: service.NumberOfUnits * service.StandardUnitPriceCredits
									}
								</td>

								<td className="service-table__column service-table__column--small service-table__column--mobile-break">
									{this.renderIndividualPrice(service.StandardUnitPriceCredits)}
								</td>

								<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__cost service-table__cost--summary">
									{isNaN(service.StandardUnitPriceCredits * service.NumberOfUnits) 
									? null : this.renderPrice(service.StandardUnitPriceCredits, service.NumberOfUnits)}
								</td>	
							</tr>
						)
					})}
					</tbody>
				</table>

				</div>
			</div>
		);
	};
}
