import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SubscriptionsCard extends Component { 

    static propTypes = {
		componentType: PropTypes.string,
	};

	static defaultProps = {
		componentType: 'div',
		currency: 'GBP',
		locale: 'en-GB'
	};

	constructor(props) {
        super(props);
    
		this.state = {
            isAnnual : this.props.isAnnual,
            totalOrder : this.props.totalOrder,
		};
    };
    
    componentDidUpdate(prevProps, prevState) {

        if(prevProps.totalOrder !== this.props.totalOrder) {
            this.setState({totalOrder: this.props.totalOrder});
        }

	}

    handleSubscriptionButton = (evt, index) => {
        this.props.handleSubscriptionSwitch(index);
    }

    changeSubscriptionTimePeriod = () => {
        
        //Handle subscription update
        this.props.handlePaymentChange(!this.state.isAnnual);
        //Call state last as its asynchronus
        this.setState({isAnnual : !this.state.isAnnual});
    }

    handleSubscriptionBackButton = () => {
        this.props.handleBackButton(false);
    }
    

    renderMinimumPurchaseLabel = (index, minimumAgents) => {

        //Nothing
        //Miniumum purchase of £50
        if(index === 0){
            return "£50 Minimum Purchase"
        }
        if(minimumAgents === 0){
            return null
        }
        return `${minimumAgents} Agent Minimum Purchase`

    }

    renderMonthly = (monthlyRate) => {
        
        const {
			currency,
			locale,
		} = this.props;

        //Originally price * noOfUnits, now changed to just price
        
        if(monthlyRate === 0){
            return null;
        }

		let formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(monthlyRate).replace(/\.00/g,'');
        
        return `${formattedPrice} per agent per month`;

    }

    renderFeatures = (description) => {

        var features = description.split("\n");
        
        //Add styling for ul and possibly li
        if (features.length) {
            return (
                <ul className="subscription_list">
                    {
                        features.map((feature, index) => {
                            return (
                                <li className="subscription_list_element" key={index}>{feature}</li>
                            )
                        })
                    }
                </ul>
            )
        }
    
        return null;
    };


    renderPrice = (price) => {
        
        //console.log(price);

		const {
			currency,
			locale,
		} = this.props;

		//Originally price * noOfUnits, now changed to just price
		let formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price).replace(/\.00/g,'');
    
        return this.state.isAnnual ? `${formattedPrice} per annum` : `${formattedPrice} per month`;
    
    };

    // parentEvent = (e) => {
    //     console.log(e);
    //     console.log(e.id);
    //     console.log(e.target);
    //     console.log("Clicking Parent");
    // }

    // childEvent = (evt, index) => {
    //     evt.stopPropagation();
    //     console.log("Clicking Child");
        
    // }

    render () {

        const {
            subscriptionsData,
            selectedIndex,
            currency,
        } = this.props;

        return (
            // <div className="card card--subscriptions">
            <div>

                <br />
                <span className="subscriptionsBackButton" onClick={() => this.handleSubscriptionBackButton()}>Back</span>
                <label className="toggle_label">
                    <strong className="toggle_strong toggle_strong--left">Monthly</strong>
                    <input className="toggle_input" type="checkbox" defaultChecked={this.state.isAnnual} onClick={() => this.changeSubscriptionTimePeriod()}/>
                    <span className="toggle_span"/>
                    <strong className="toggle_strong toggle_strong--right">Yearly</strong>
                </label>

                <br />
                <br />

                <div className="subscription_card_options_overlay">
                { subscriptionsData.map((item, index) => {

                    //console.log(item);

                    //let price = this.state.isAnnual ? (item[currency].StandardUnitPriceCurrency - ((item[currency].StandardUnitPriceCurrency / 12) * item.TrialPeriodTimeUnits)) : parseFloat(item[currency].PremiumUnitPriceCurrency / 12);

                    let unitPrice = this.state.isAnnual ? item[currency].StandardUnitPriceCurrency : item[currency].PremiumUnitPriceCurrency;
                    
                    // let minAgent = item.DefaultAgentsAllowed;

                    // if(minAgent == 0) {
                    //     minAgent = 1;
                    // }

                    //let monthlyRate = this.state.isAnnual ? (item[currency].StandardUnitPriceCurrency / (12 * minAgent)) : (item[currency].PremiumUnitPriceCurrency / (12 * minAgent))


                    if (isNaN(unitPrice))
                    unitPrice = 0;

                    
                    let units = item.NumberOfUnits;
                    if(index === selectedIndex){
                        //console.log(units);
                        if(units == 0){
                            units = item.DefaultAgentsAllowed;
                        }
                    }
                    
                    //Check total is less than £50
                    if(index === 0 && selectedIndex === index && this.state.totalOrder < 50){
                        let order = this.state.totalOrder;
                        alert("There is a minimum purchase level of £50. Please select 'Back' and confirm the level of credits you require.");
                    }

                    return (
                    <div id="parent" key={index} className={selectedIndex === index ? "subscription_card_options_element--selected" : "subscription_card_options_element"}>
                    
                    <div className="subscription_card_options_element_short_description">

                    <h2>{item.Name}</h2>
                    <br />
                    <table className="service-table">
                    <tbody>
                    <tr key={index}>
                        <td>
                            {index !== 0 ? <span className="service-table__title">No. of Agents</span> : <br/>}
                        </td>
                        <td>
                        {index !== 0 ?
                            <input
                                type="number"
                                min={0} 
                                max={100000}
                                disabled={selectedIndex !== index}
                                value={
                                    isNaN(units) ? '' 
                                    : units < 0 ? 0 
                                    : units === 0 ? ''
                                    : units > item.MaxUnits ? item.MaxUnits
                                    : units
                                }
                                onChange={(evt) => this.props.handleSubscriptionChange(evt, index)}
                            /> : <br/>
                        }
                        </td>
                    </tr>
                    </tbody>
                    </table>
                    <br />

                    <div>{item.ShortDescription}</div>
                    <br />
                   
                    {this.renderMinimumPurchaseLabel(index, item.DefaultAgentsAllowed)}
                    <br />
                    {this.renderMonthly(unitPrice)}
                    <br />
                    <br />
                    <div className="subscription_card_options_element_description_button">
                        <button
                        className={selectedIndex === index ? "card_button card_button--selected" : "card_button"}
                        type="button"
                        onClick={(evt) => this.handleSubscriptionButton(evt, index)}
                        >
                        <span>Select</span>
                        </button>
                    </div>
                    <br />
                    </div>

                    {this.renderFeatures(item.Description)}
                    <br />       
                    </div>);
                })}
                </div>
                
            </div>
        );
    }

}