import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './style/Loader.css';

export default class Loader extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div',
		active: false
	};

	render() {
		const {
			componentType,
			className,
			active,
			activeName,
			loaderName,
			spinnerName

		} = this.props;

		const Element = componentType;
		const activeClass = active ? `${activeName ? activeName : ''}` : '';
		const customClassNames = className ? className : '';
		const spinner = spinnerName ? spinnerName : '';
		const loader = loaderName ? loaderName : '';
		const classNames = `${loader} ${activeClass} ${customClassNames}`;

		return (
			<Element className={classNames}>
				<span className={spinner} />
			</Element>
		);
	}
}
