import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ServicePacks from '../ServicePacks/ServicePacks';

export default class ServiceData extends Component {

	static propTypes = {
		componentType: PropTypes.string,
		currency: PropTypes.string,
		locale: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div',
		currency: 'GBP',
		locale: 'en-GB'
	};

	constructor(props) {
		super(props);
	}

	componentWillMount() {
        if (!this.props.SpotliteClientCatalogue) {
		    return this.props.getSpotliteClientCatalogue();
        }
	}

	renderServicePacks = () => {
        const {forename, eMail, SpotliteClientCatalogue, currency, locale, servicePacksState, totalExclVat, subscriptionsData} = this.props;    

        if (SpotliteClientCatalogue) {
            return (
                <ServicePacks
                    forename={forename}
                    eMail={eMail}
                    SpotliteClientCatalogue={SpotliteClientCatalogue}
                    totalExclVat={totalExclVat}
                    calculateOrderTotal={this.props.calculateOrderTotal}
                    handleServicePackSubmit={this.props.handleServicePackSubmit}
                    currency={currency}
                    locale={locale}
					servicePacksState={servicePacksState}
					subscriptionsData={subscriptionsData}
                />
            );
        } 
	};

	render() {
		return (
            <div>
				{this.renderServicePacks()}
            </div>
		);
	};
}