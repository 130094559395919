import { useEffect, useState, useRef, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Headline from '../Headline/Headline';
import Modal from '../Modal/Modal';

import SubmitPayment from './SubmitPayment';
import { getRequest, postRequest, apiParse } from '../../utils/Common';
import config from '../../config';

import './style/PaymentMethod.css';

const env = process.env.NODE_ENV || "development";

// Hook to mimic setState method when passing in a callback function
const useSetStateCallback = (currentState) => {
    const [ state, setState ] = useState(currentState);
    const callbackRef = useRef(null);
    
    const setStateCallback = useCallback((state, callback) => {
        callbackRef.current = callback; 
        setState(state);
    }, []);
    
    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current(state);
            callbackRef.current = null;
        }
    }, [state]);
    
    return [state, setStateCallback];
};

const PaymentMethod = (props) => {
    const [ stripePromise, setStripePromise ] = useState(null);
    const [ clientSecret, setClientSecret ] = useState('');
    const [ id, setid ] = useState(null);
    const [ payByInvoiceModalOpen, setPayByInvoiceModalOpen ] = useSetStateCallback(false);
    const [ workInProgress, setWorkInProgress ] = useSetStateCallback(false);

    const URI = config[env].URL;
    const headers = { 'appkey': process.env.REACT_APP_APP_KEY };
    const credentials = 'omit';

    useEffect(() => {
        getRequest(`${URI}/get-payment-config`, credentials, headers)
            .then(apiParse)
            .then(publishableKey => setStripePromise(loadStripe(publishableKey)))
            .catch(err => props.setError(err = err.message || err));
    }, []);

    useEffect(() => {
        const options = {
            description: props.orderDescription,
            amount: props.orderTotal,
            currency: props.currencyCode,
        };
        postRequest(`${URI}/create-payment-intent`, options, credentials, headers)
            .then(apiParse)
            .then(response => {
                setid(response.id);
                setClientSecret(response.clientSecret);
                return;
        })
            .catch(err => props.setError(err = err.message || err));
    }, []);

    const handleSubmit = (event) => {
		event.preventDefault();
		setWorkInProgress(
            () => true, 
            currentState => currentState
            // currentState => currentState ? this.submitButton.setAttribute('disabled', 'disabled') : this.submitButton.removeAttribute('disabled')
        );

		handlePayByInvoiceModalClose(() => props.handlePayByInvoiceButton(id));
	};

    const handlePayByInvoiceModalClose = (callback = currentState => currentState) => {
        setPayByInvoiceModalOpen(() => false, callback);
	};

	const handlePayByInvoiceModalOpen = (event) => {
		event.preventDefault();
        setPayByInvoiceModalOpen(() => true, currentState => currentState);
	};

    const getButtonText = (buttonText) => {
		return workInProgress ? "Please Wait..." : buttonText;
	}	

    const renderPaymentSection = () => {
        return (
            <Elements 
                stripe={stripePromise} 
                options={{ clientSecret }}
            >
                <SubmitPayment 
                    handlePaymentSuccess={props.handlePaymentSuccess}
                />
            </Elements>
        );
	};

    const renderPayByInvoiceButton = () => {
		return ([
			<button
				className="button button--alternative"
				type="button"
				disabled={workInProgress}
				onClick={handlePayByInvoiceModalOpen}
				key="payByInvoiceButton"
			>
				{ getButtonText('Pay by Invoice') }
			</button>,
			<Modal
				open={payByInvoiceModalOpen}
				onClose={handlePayByInvoiceModalClose}
				key="payByInvoiceModal"
			>
                    <Headline componentType="h4">
                        Pay By Invoice
                    </Headline>
                    <p>Please note that by paying by invoice you will not be able to activate the service until your payment has been received.</p>

                    <form
                        name="payByInvoice"
                        id="payByInvoice"
                        className="form"
                        onSubmit={handleSubmit}
                    >
						<fieldset className="form__fieldset">
							<span />
							<button
								className="button button--negative"
								type="button"
								onClick={() => {
									handlePayByInvoiceModalClose();
								}}
							>
								Go Back
							</button>
                            <button
                                className="button"
                                type="submit"
                            >
								Confirm
							</button>
                        </fieldset>
                    </form>
			</Modal>
		])
	};

    if (stripePromise && clientSecret) {
        return (
            <div className="payment-method">
                <Headline
                    componentType="h2"
                    type="page"
                >
                    Payment Method
                </Headline>

                <div className="section payment-method__section">
                    <Headline
                        componentType="h3"
                    >
                        Please fill out your billing details for your payment method
                    </Headline>

                    { renderPaymentSection() }

                    { renderPayByInvoiceButton() }
                </div>
            </div>
        );
    } else { 
        return (<></>) 
    };
};

export default PaymentMethod;