import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Headline from '../Headline/Headline';
import ServicePackCatalogue from '../ServicePacks/ServicePackCatalogue';
import ServicePackServices from '../ServicePacks/ServicePackServices'
import ServicePackItems from '../ServicePacks/ServicePackItems';
import ServicePackSubscriptions from '../ServicePacks/ServicePackSubscriptions';
import scrollToComponent from 'react-scroll-to-component';
// import ServiceTotal from '../ServicePacks/ServiceTotal';
import { getRequest, apiParse } from '../../utils/Common';
import deepCopy from '../../utils/deepCopy';
import AutoModal from '../AutoModal/AutoModal';
import Modal from '../Modal/Modal';
//import SpotliteClientCatalogue from '../../content/ractalogue_v11.json';

import './style/ServicePacks.css';
import './style/ServiceTable.css';

import config from '../../config';
import SubscriptionsCard from './SubscriptionsCard';
import { validateEmail } from '../../utils/Validation';
const env = process.env.NODE_ENV || 'development';

export default class ServicePacks extends Component {

	static propTypes = {
		componentType: PropTypes.string,
		currency: PropTypes.string,
		locale: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div',
		currency: 'GBP',
		locale: 'en-GB'
	};

	constructor(props) {
		super(props);

		//console.log("Constructor");

		this.state = {
			catalogueSelected: null,
			catalogueName: null,
			notFirstTimeSelected: false,
			promoCode: '',
			promoSelected: null,
			numberOfCredits: 0,
			promoCodeValid: false,
			error: null,
			message: null,
			jurisdiction: 'GBR',
			packSelected: null,
			// subscriptionSelected: null,
			// subscriptionSelected: [],
			subscriptionSelected: deepCopy(this.props.SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
			//subscriptionSelected: deepCopy(SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
			totalSubscriptionCost: 0,//this is calculated before render
			addonSelected: [],
			totalAddOnsCost: 0,
			displayServiceItems: false,
			totalCostServicePackItems: 0,
			displayPaymentOptions: false,
			serviceItemChange: false,
			selectedPayment: "Annually",
			isAnnual: true,
			//totalCreditAmount: 0,
			conversionRate: 1,
			servicesData: [],
			subscriptionsData: deepCopy(this.props.subscriptionsData),
			addonData: [],
			totalOrder: 0,
			totalOrderInclTax: 0,
			spotliteStartDate: "",
			spotliteEndDate: "",
			spotliteDueDate: "",
			billingFrequency: "",
			index: 1,
			quoteButtonDisabled: true,
			isSubscriptionsVisible: false,
			learnMoreModalOpen: false,
			ServiceID: "",
			dbs: false,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		if (this.props.servicePacksState) {
			this.setState({
				...this.props.servicePacksState
			})
		}

		//Setup a default SpotliteSubscriptions
		let subscriptions = deepCopy(this.state.subscriptionSelected);
		let item = subscriptions[this.state.index];
		let subscriptionsData = deepCopy(this.state.subscriptionsData);

		if(subscriptionsData.length === 0){
			//console.log("Array is length 0")
			// Update the subscriptionData
			subscriptionsData.push({
				Name: item.Name,
				Type: item.Type,
				ShortDescription: item.ShortDescription,
				Description: item.Description,
				TimeType: item.TimeType,
				ServiceDurationTimeUnit: item.ServiceDurationTimeUnit,
				FreeService: item.FreeService,
				FreeUnits: item.FreeUnits,
				FreeCredits: item.FreeCredits,
				TrialPeriodTimeUnits: item.TrialPeriodTimeUnits,
				TrialPeriodType: item.TrialPeriodType,
				MinUnits: item.MinUnits,
				MaxUnits: item.MaxUnits,
				NumberOfUnits: item.DefaultAgentsAllowed,//item.NumberOfUnits,
				StandardUnitPriceCredits: item.StandardUnitPriceCredits,
				DefaultThresholdCreditLevel: item.DefaultThresholdCreditLevel,
				DefaultAgentsAllowed: item.DefaultAgentsAllowed,
				DefaultApplicantsAllowed: item.DefaultApplicantsAllowed,
				DefaultOrderUnits: item.DefaultOrderUnits,
				ClientCatalogueID: item.ClientCatalogueID,
				SystemControlLevel: item.SystemControlLevel,
				RBACSettings: {
					AgentAppEnabled: item.SetAgentAppEnabled,
					AgentCRMEnabled: item.SetAgentCRMEnabled,
					ApplicantAppEnabled: item.SetApplicantAppEnabled,
					ApplicantCRMEnabled: item.SetApplicantCRMEnabled,
					SafeGuardenEnabled: item.SetSafeGuardenEnabled
				},
				BillingCalculationMethod: item.BillingCalculationMethod,
				BillingFrequency: item.BillingFrequency,
				VAT: item.VAT,
				PriceExclTax: 0,
				PriceInclTax: 0,
				Tax: 0,
				Credits: 0,
				isAnnual: this.state.isAnnual
			});

		}

		this.setState({ subscriptionsData: subscriptionsData });
		this.setDate(this.state.isAnnual, item.TrialPeriodTimeUnits);

	};

	componentDidUpdate(prevProps, prevState) {

		if (this.state.catalogueSelected !== prevState.catalogueSelected) {
			this.setState({
				notFirstTimeSelected: true
			});
		}

	}

	/*
	setDefaultSubscriptionCost = () => {
		
		const {
			currency,
		} = this.props;

		//Upon mounting the component we immediately calculate the default subscription price and credtis for default subscription
		//Calculate new price
		let subscriptions = deepCopy(this.state.subscriptionSelected);
		let item = subscriptions[this.state.index];
		
		let unitPrice = this.state.isAnnual ? item[currency].StandardUnitPriceCurrency : item[currency].PremiumUnitPriceCurrency;
		let annualPrice = this.state.isAnnual ? (unitPrice * (12 - item.TrialPeriodTimeUnits)) : (unitPrice * 12)

		let minAgents = item.DefaultAgentsAllowed;

		if(minAgents == 0) {
			minAgents = 1;
		}

		let totalSubscriptionCost = parseFloat(annualPrice * minAgents);

		//console.log(totalSubscriptionCost);
	
		return totalSubscriptionCost;
	}*/

	handleCatalogueSelect = (catalogueID, name) => {

		this.setState({
			catalogueSelected: catalogueID,
			catalogueName: name
		});
	};

	handlePackSelect = pack => {

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		//Upon mounting the component we immediately calculate the default subscription price and credtis for default subscription
		//Calculate new price
		let subscriptions = deepCopy(this.state.subscriptionSelected);
		let item = subscriptions[this.state.index];
		
		let unitPrice = this.state.isAnnual ? item[currency].StandardUnitPriceCurrency : item[currency].PremiumUnitPriceCurrency;
		let annualPrice = this.state.isAnnual ? (unitPrice * (12 - item.TrialPeriodTimeUnits)) : (unitPrice * 12)
		let minAgents = item.DefaultAgentsAllowed;

		if(minAgents == 0) {
			minAgents = 1;
		}

		item.NumberOfUnits = minAgents;

		let Credits = this.state.isAnnual ? (item.FreeCredits * minAgents): 0;

		let totalSubscriptionCost = parseFloat(annualPrice * minAgents);

		let PriceExclTax = parseFloat(annualPrice * minAgents);
		let PriceInclTax = parseFloat(PriceExclTax + (PriceExclTax * taxPercentage));
		let Tax = parseFloat((PriceExclTax * taxPercentage).toFixed(2));
		
		//console.log(PriceExclTax, PriceInclTax, Tax);

		let subscriptionsData = [];

		// Update the subscriptionData
		subscriptionsData.push({
			Name: item.Name,
			Type: item.Type,
			ShortDescription: item.ShortDescription,
			Description: item.Description,
			TimeType: item.TimeType,
			ServiceDurationTimeUnit: item.ServiceDurationTimeUnit,
			FreeService: item.FreeService,
			FreeUnits: item.FreeUnits,
			FreeCredits: item.FreeCredits,
			TrialPeriodTimeUnits: item.TrialPeriodTimeUnits,
			TrialPeriodType: item.TrialPeriodType,
			MinUnits: item.MinUnits,
			MaxUnits: item.MaxUnits,
			NumberOfUnits: item.DefaultAgentsAllowed,//item.NumberOfUnits,
			StandardUnitPriceCredits: item.StandardUnitPriceCredits,
			DefaultThresholdCreditLevel: item.DefaultThresholdCreditLevel,
			DefaultAgentsAllowed: item.DefaultAgentsAllowed,
			DefaultApplicantsAllowed: item.DefaultApplicantsAllowed,
			DefaultOrderUnits: item.DefaultOrderUnits,
			ClientCatalogueID: item.ClientCatalogueID,
			SystemControlLevel: item.SystemControlLevel,
			RBACSettings: {
				AgentAppEnabled: item.SetAgentAppEnabled,
				AgentCRMEnabled: item.SetAgentCRMEnabled,
				ApplicantAppEnabled: item.SetApplicantAppEnabled,
				ApplicantCRMEnabled: item.SetApplicantCRMEnabled,
				SafeGuardenEnabled: item.SetSafeGuardenEnabled
			},
			BillingCalculationMethod: item.BillingCalculationMethod,
			BillingFrequency: item.BillingFrequency,
			VAT: item.VAT,
			PriceExclTax: PriceExclTax,
			PriceInclTax: PriceInclTax,
			Tax: Tax,
			Credits: Credits,
			isAnnual: this.state.isAnnual
		});

		let totalOrder = (totalSubscriptionCost + this.state.totalAddOnsCost);

		let totalOrderInclTax = totalOrder + (totalOrder*taxPercentage);

		const str = pack.ServicePackCode;
		const first6 = str.slice(0, 6);
		
		this.setState({
			subscriptionsData: subscriptionsData,
			subscriptionSelected: subscriptions,
			packSelected: pack,
			conversionRate: 1,
			numberOfCredits: 0,
			totalCostServicePackItems: 0,
			totalSubscriptionCost: totalSubscriptionCost,
			totalOrder: totalOrder,
			totalOrderInclTax: totalOrderInclTax,
			displayServiceItems: true,
			isSubscriptionsVisible: false,
			quoteButtonDisabled: this.handleDisableQuoteButton(totalSubscriptionCost),
			dbs: first6 === 'RTWDBS',
		});

		window.scrollTo(0, 0);
		// scrollToComponent(this.serviceItemSection, {
		// 	offset: 0,
		// 	align: 'top',
		// 	duration: 100
		// })

	};

	handleContinue = () => {
		this.setState({
			displayServiceItems: false,
			displayPaymentOptions: true
		})
	}

	/*
	handleSubscriptionSelect = subscription => {

		//console.log("Subscription selected ", subscription);

		this.setState({
			subscriptionSelected: subscription
		});
	};
	*/

	handleAddonSelect = (addon) => {

		const {
			totalSubscriptionCost,
			totalCostServicePackItems
		} = this.state;

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		//Grab Payment Methods and look against index 0
		const paymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;
		let creditType = paymentMethods.CreditTypes[0];

		// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		let updatedAddOnsCost = 0;

		let addonElement = {
			Name: addon.Name,
			PriceExclTax: addon[currency].PremiumUnitPriceCurrency,
			PriceInclTax: (addon[currency].PremiumUnitPriceCurrency + (addon[currency].PremiumUnitPriceCurrency * taxPercentage)),
			Tax: (addon[currency].PremiumUnitPriceCurrency * taxPercentage),
			Type: addon.Type
		};

		if ([...this.state.addonData].some(item => item.Name === addonElement.Name)) {

			const newArray = [...this.state.addonData];
			const index = newArray.findIndex(item => item.Name === addonElement.Name);
			newArray.splice(index, 1);
			newArray.forEach(item => updatedAddOnsCost += item.PriceExclTax);

			this.setState({
				addonData: newArray
			});

		} else {

			[...this.state.addonData, addonElement].forEach(item => updatedAddOnsCost += item.PriceExclTax);

			this.setState({
				addonData: [...this.state.addonData, addonElement]
			});
		}

		let total = totalSubscriptionCost + totalCostServicePackItems + updatedAddOnsCost;
		let totalInclTax = (total) + (total * taxPercentage);

		let isDisable = this.handleDisableQuoteButton(total);

		this.setState({
			totalAddOnsCost: updatedAddOnsCost,
			totalOrder: total,
			totalOrderInclTax: totalInclTax,
			quoteButtonDisabled: isDisable,
		});
	};

	handlePromoChange = event => {
		this.setState({
			promoCode: event.target.value,
			promoCodeValid: false,
			error: null,
			message: null
		})
	};

	handlePromoButton = () => {
		const { eMail } = this.props;
		let { promoCode, numberOfCredits } = this.state;

		promoCode = promoCode.replace(/^\s+|\s+$/g, '');

		// Remove any leading/tailing white space which can be a problem on smartphones and tables
		this.setState({
			promoCode
		});

		const searchTerm = `?code=${promoCode}&email=${eMail}`;
		const URI = `${config[env].URL}/promo-code${searchTerm}`;
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		getRequest(URI, credentials, headers)
			.then(apiParse)
			.then(response => {
				const {
					PromotionCode,
					AdditionalFreeCredits,
					WaiveRegistrationFee,
					...promoDetails
				} = response;
				let noRegistrationFee = WaiveRegistrationFee === 'YES' ? true : false;

				// There may be free promotion credits to add to the total credit amount
				let totalCredits = numberOfCredits + (AdditionalFreeCredits ? AdditionalFreeCredits : 0);

				this.setState({
					promoCodeValid: true,
					numberOfCredits: totalCredits,
					quoteButtonDisabled: false,
					promoSelected: {
						Name: PromotionCode,
						Type: 'PROMO',
						Credits: AdditionalFreeCredits,
						WaiveRegistrationFee: noRegistrationFee,
						...promoDetails
						/* PROMO REFERRAL - Promotion Code Referrer update commented out for now
												ReferrerCompanyNumber: response.ReferrerCompanyNumber,
												ReferrerDepartmentName: response.ReferrerDepartmentName,
												ReferrerSecure_Id: response.ReferrerSecure_Id,
												ReferralCredits: response.ReferralCredits,
						*/
						// Description: response.Description
					},
					error: null,
					message: 'Promotional code accepted'
					/* promoSelected: {
						Name: 'Promotion',
						Type: 'PROMO',
						PromoCode: response.PromotionCode,
					*/
					// Amount: response.NumberOfCredits,
					/*
						Amount: response.AdditionalFreeCredits,
						VAT: false,
						GBP: {
							Price: 0,
							Tax: 0
						},
						WaiveRegistrationFee: response.WaiveRegistrationFee === 'YES' ? true : false,
					*/
					/* PROMO REFERRAL - Promotion Code Referrer update commented out for now
											ReferrerCompanyNumber: response.ReferrerCompanyNumber,
											ReferrerDepartmentName: response.ReferrerDepartmentName,
											ReferrerSecure_Id: response.ReferrerSecure_Id,
											ReferralCredits: response.ReferralCredits,
					*/
					// Description: response.Description
				});
			})
			.catch(err => {
				this.setState({
					promoCodeValid: false,
					promoSelected: null,
					error: (err.message || err),
					message: null
				});
			});
	};

	handleSubmit = event => {
		event.preventDefault();

		if (this.state.promoCodeValid && this.state.packSelected) {
			this.props.handleServicePackSubmit({ promoCode: this.state.promoCode });
		}
	};

	handleConfirmButton = () => {

		const {
			catalogueSelected,
			catalogueName,
			promoCode,
			numberOfCredits,
			packSelected,
			addonSelected,
			promoSelected,
			subscriptionSelected,
			subscriptionsData,
			servicesData,
			addonData,
			totalOrder,
			totalOrderInclTax,
			spotliteStartDate,
			spotliteEndDate,
			spotliteDueDate,
			billingFrequency,
			isSubscriptionsVisible,
		} = this.state;

		const { currency } = this.props;

		// Filter out paid subscriptions not chosen by the customer
		// let subscriptions = subscriptionSelected.filter(subscription => {
		// 	return (parseInt(subscription.NumberOfUnits) && subscription.NumberOfUnits >= subscription.FreeUnits);
		// });

		// Filter out services that have not been ordered by the customer
		// let services = packSelected.Jurisdictions[0].LinesOfBusiness[0].Services.filter(pack => parseInt(pack.NumberOfUnits, 10) > 0);

		let filterdeSubscriptions = subscriptionsData.filter(subscription => {
			return subscription.NumberOfUnits > 0;
		});

		let subscriptionCredits = 0;

		filterdeSubscriptions.forEach(item => {
			subscriptionCredits += item.Credits;
		});

		//console.log(subscriptionCredits);

		let filteredServices = servicesData.filter(service => service.Credits > 0);

		//console.log(filteredServices);
		//console.log(numberOfCredits);

		const purchaseOptions = {
			catalogueSelected: catalogueSelected,
			catalogueName: catalogueName,
			promoCode: promoCode,
			numberOfCredits: numberOfCredits,
			freeCredits: subscriptionCredits,
			subscriptionSelected: subscriptionSelected,
			packSelected: packSelected,
			addonSelected: addonData,
			promoSelected: promoSelected,
			subscriptions: filterdeSubscriptions,
			services: filteredServices,
			//servicesData: filteredServices,
			//subscriptionsData: filterdeSubscriptions,
			totalOrderExclTax: totalOrder,
			totalOrderInclTax: totalOrderInclTax,
			spotliteStartDate: spotliteStartDate,
			spotliteEndDate: spotliteEndDate,
			spotliteDueDate: spotliteDueDate,
			billingFrequency: billingFrequency,
			currencyCode: currency,
			dbs: this.state.dbs,
		};

		//console.log(purchaseOptions, this.state)

		this.setState({ message: null });

		this.props.handleServicePackSubmit(purchaseOptions, this.state);
	};

	onCatalogueBack = () => {

		this.setState({
			catalogueSelected: null,
			catalogueName: null,
			promoCode: '',
			packSelected: null,
			displayServiceItems: false,
			isSubscriptionsVisible: false,
			displayPaymentOptions: false,
			totalSubscriptionCost: 0,
			subscriptionSelected: deepCopy(this.props.SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
			//subscriptionSelected: deepCopy(SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
			servicesData: [],
			totalCostServicePackItems: 0,
			serviceItemChange: false,
			totalAddOnsCost: 0,
			addonSelected: [],
			conversionRate: 1,
			numberOfCredits: 0,
			totalOrder: (this.state.totalSubscriptionCost + this.state.totalAddOnsCost),
			dbs: false,
		});

	};

	handleServiceBackButton = () => {

		this.setState({
			error: null,
			message: null,
			promoCode: '',
			promoSelected: null,
			promoCodeValid: false,
			packSelected: null,
			numberOfCredits: 0,
			totalSubscriptionCost: 0,
			subscriptionSelected: deepCopy(this.props.SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
			//subscriptionSelected: deepCopy(SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
			servicesData: [],
			totalCostServicePackItems: 0,
			serviceItemChange: false,
			totalAddOnsCost: 0,
			addonSelected: [],
			displayServiceItems: false,
			isSubscriptionsVisible: false,
			totalOrder: 0,
			quoteButtonDisabled: true,
			isAnnual: true,
			index: 1,
			dbs: false,
		});
	}


	handleBackButton = () => {

		const {
			displayServiceItems,
			isSubscriptionsVisible,
		} = this.state;


		if (isSubscriptionsVisible) {
			//Make Subscriptions invisible
			window.scrollTo(0, 0);
			// scrollToComponent(this.serviceItemSection, {
			// 	offset: 0,
			// 	align: 'top',
			// 	duration: 100
			// })

			this.setState({
				isSubscriptionsVisible: false,
				displayServiceItems: true,
			});

		} else if (displayServiceItems) {
			window.scrollTo(0, 0);
			// scrollToComponent(this.serviceItemSection, {
			// 	offset: 0,
			// 	align: 'top',
			// 	duration: 100
			// })

			this.setState({
				error: null,
				message: null,
				promoCode: '',
				packSelected: null,
				numberOfCredits: 0,
				totalSubscriptionCost: 0,
				subscriptionSelected: deepCopy(this.props.SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
				//subscriptionSelected: deepCopy(SpotliteClientCatalogue.Currencies.GBP.PaymentMethods.AnnualTypes),
				totalCostServicePackItems: 0,
				serviceItemChange: false,
				totalAddOnsCost: 0,
				addonSelected: [],
				displayServiceItems: false,
				isSubscriptionsVisible: false,
				quoteButtonDisabled: true,
				totalOrder: 0,
				isAnnual: true,
				index: 1,
			});
		}
	};

	renderServices = () => {
		const catalogues = this.props.SpotliteClientCatalogue.Catalogue;
		//const catalogues = SpotliteClientCatalogue.Catalogue;
		const notFirstTimeSelectedClass = !this.state.catalogueSelected && this.state.notFirstTimeSelected ? ' services--animate' : '';
		const servicesClassNames = `services${this.state.catalogueSelected ? ' services--selected' : ''}${notFirstTimeSelectedClass}`;


		//console.log(this.state.catalogueSelected, this.state.displayServiceItems, this.state.isSubscriptionsVisible);

		//determine if catalogue or pack has been selected. And displayServiceItems is false
		if (this.state.catalogueSelected && !this.state.displayServiceItems && !this.state.isSubscriptionsVisible) {
			//if catalogue selected, render services component
			return (
				<>
					<div className="services_header">

						<div className="services_header_button"
							// type="button"
							onClick={() => this.onCatalogueBack()}>
							<span>Back</span>
						</div>

						<div className="services_header_element">
							<Headline componentType="h4">
								{catalogues.find((catalogue) => catalogue.CatalogueCode === this.state.catalogueSelected).CatalogueName}
							</Headline>
						</div>

						<div className="header_overlay">
							<AutoModal icon="info-black" iconClassName="header_info_icon">
								<Headline componentType="h4">{catalogues.find((catalogue) => catalogue.CatalogueCode === this.state.catalogueSelected).CatalogueName}</Headline>
								{this.renderFeatures(catalogues.find((catalogue) =>
									catalogue.CatalogueCode === this.state.catalogueSelected).Features)}
							</AutoModal>
						</div>

					</div>

					<ul className="services">
						{
							//Maps the services
							catalogues.find((catalogue) => catalogue.CatalogueCode === this.state.catalogueSelected)
								.ServicePacks.map((service, index) => {
									return (
										<ServicePackServices
											key={index}
											service={service}
											catalogueName={catalogues.find((catalogue) => catalogue.CatalogueCode === this.state.catalogueSelected).CatalogueName}
											catalogueFeatures={catalogues.find((catalogue) => catalogue.CatalogueCode === this.state.catalogueSelected).Features}
											onPackSelect={this.handlePackSelect}
											packSelected={this.state.packSelected}
											onBackClick={this.onCatalogueBack}>
										</ServicePackServices>
									)
								})
						}
					</ul>

				</>
			)
		} else if (!this.state.catalogueSelected) {
			//if catalogue is not selected, render catalogues component
			return (

				<ServicePackCatalogue
					catalogues={catalogues}
					onCatalogueSelect={this.handleCatalogueSelect}
					catalogueSelected={this.state.catalogueSelected} />

			)
		}

	};

	renderFeatures = features => {
		if (features.length) {
			return (
				<ul>
					{
						features.map((feature, index) => {
							return (
								<li key={index}>{feature}</li>
							)
						})
					}
				</ul>
			)
		}

		return null;
	};

	displaySubscription = (isVisible) => {
		//Set visibility of subscriptions and add ons
		//Set visibility of services to the opposite of subscriptions and add ons
		window.scrollTo(0, 0);
		// scrollToComponent(this.serviceItemSection, {
		// 	offset: 0,
		// 	align: 'top',
		// 	duration: 100
		// })

		this.setState({ isSubscriptionsVisible: isVisible, displayServiceItems: !isVisible });
	}

	renderCurrentPurchase = () => {

		//let freeCredits = 0;

		//While an array, it will never be greater than 1
		// this.state.subscriptionsData.forEach(item => {
		// 	freeCredits = this.state.isAnnual ? item.FreeCredits : (item.FreeCredits / 12);
		// });

		/*
		var units = 1;

		this.state.subscriptionsData.forEach(item => {
			//console.log(item.Name, item.NumberOfUnits);
			units = item.NumberOfUnits; 
			freeCredits = this.state.isAnnual ? (item.FreeCredits * units) : 0;
		});
		*/

		return (
			<div className="promotions">
				{this.renderMinimumPurchaseLabel()}
				<table className="service-table service-table__total">
					<tbody>
						<tr>
							<td className="service-table__column service-table__carry_over_cost service-table__column--mobile-break">
								Credits Carried Over
							</td>

							<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right service-table__carry_over_cost">
								{this.state.numberOfCredits}
							</td>
						</tr>
						<tr>
							<td className="service-table__column service-table__carry_over_cost service-table__column--mobile-break">
								Subscription Bonus Credits
							</td>

							<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right service-table__carry_over_cost">
								{this.handleFreeCredits()}
							</td>
						</tr>
						<tr>
							<td className="service-table__column service-table__carry_over_cost service-table__column--mobile-break">
								Order Value (Excluding VAT)
							</td>

							<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right service-table__carry_over_cost">
								{this.renderCarryOverPrices(this.state.totalOrder)}
							</td>
						</tr>

					</tbody>
				</table>
				<br />
			</div>
		);
	}

	renderServiceSubscriptions() {

		if (!this.state.isSubscriptionsVisible) {
			return null;
		}

		return (

			<>

				{this.renderCurrentPurchase()}

				{/* If pack selected has RTWDBS in code, show the following message else hide */}
				{this.state.dbs ? <div>
					If you have selected DBS checks please note that the price indicated does not include the DBS fees of £18 for each standard check and £38 for each enhanced check.
				</div> : null }
				

				<SubscriptionsCard
					subscriptionsData={this.state.subscriptionSelected}
					selectedIndex={this.state.index}
					isAnnual={this.state.isAnnual}
					totalOrder={this.state.totalOrder}
					handleSubscriptionSwitch={this.handleSubscriptionSwitch}
					handleSubscriptionChange={this.handleSubscriptionChange}
					handlePaymentChange={this.calculateCurrentOrderTotal}
					handleBackButton={this.displaySubscription}
				/>

			</>
		);

	}

	renderAddOn = () => {

		// if(!this.state.isSubscriptionsVisible) {
		// 	return null;
		// }

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		const PaymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;

		let addOnTitle = PaymentMethods.AddOnTypes.length > 0 ? 'AddOns' : null

		if (!this.state.isSubscriptionsVisible || addOnTitle === null) {
			return null;
		}

		return (
			<div className="card card--addOns">
				<Headline componentType="h3">{addOnTitle}</Headline>
				<table className="service-table">
					<tbody>
						{PaymentMethods.AddOnTypes.map((option, index) => {
							//const addonSelected = this.state.addonSelected.includes(option);
							const addonSelected = this.state.addonData.some(item => item.Name === option.Name);

							return (
								<tr key={index}>
									<td className="service-table__column service-table__column--mobile-break">
										<span className="service-table__cost">{option.Name}</span>
										<span className="service-table__title">
											{this.renderPrice(option[currency].PremiumUnitPriceCurrency, option.DefaultOrderUnits)}
										</span>
										<span className="service-table__description">{option.Description}</span>
										<span className="service-table__description"><a href={option.Website} target="_blank" rel="noopener noreferrer">{option.Website}</a></span>
									</td>

									<td className="service-table__column service-table__column--small service-table__column--right service-table__column--mobile-break">
										<button
											className={`button ${addonSelected ? '' : 'button--alternative'}`}
											type="button"
											onClick={() => this.handleAddonSelect(option)}>
											{addonSelected ? 'OK' : 'Add'}
										</button>
									</td>
									<td className="service-table__column service-table__column--small service-table__column--right service-table__column--mobile-break service-table__cost service-table__cost--summary">
										{addonSelected ? this.renderPrice(option[currency].PremiumUnitPriceCurrency, option.DefaultOrderUnits) : null}
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		);
	}


	renderSubscriptionsButton = () => {
		
		if (this.state.isSubscriptionsVisible) {
			return null;
		}

		return (
			<button
				className="subscription_service_button"
				type="button"
				onClick={() => this.displaySubscription(!this.state.isSubscriptionsVisible)}
			>
				<span>Choose your Subscription</span>
			</button>
		);
		
	}

	renderPromotions = () => {

		const {
			packSelected,
			promoCode,
			promoCodeValid,
			error,
			message,
		} = this.state;

		if (!this.state.isSubscriptionsVisible) {
			return null;
		}

		return (
			<div className="promotions">
				<Headline componentType="h3">Promotions</Headline>

				<p className="terms">If you have a referral or promo code please enter it below.</p>

				<form
					name="paymentMethod"
					id="paymentMethod"
					onSubmit={this.handleSubmit}>

					<table className="service-table">
						<tbody>
							<tr>
								<td className="service-table__column service-table__column--mobile-break">
									<input
										className="form__input"
										type="text"
										value={promoCode}
										onChange={this.handlePromoChange}
										placeholder="Promo code" />
								</td>
								<td className="service-table__column service-table__column--medium service-table__column--right service-table__column--mobile-break">
									<input
										className="button"
										type="submit"
										value="Add"
										disabled={!packSelected || !promoCode || promoCodeValid}
										onClick={this.handlePromoButton} />
								</td>
							</tr>
						</tbody>
					</table>
					<div className={error ? "form__message--error" : "form__message"}>
						{error ? error : message ? message : <br />}
					</div>
				</form>

				{this.renderMinimumPurchaseLabel()}

				<table className="service-table service-table__total">
					<tbody>
						<tr>
							<td className="service-table__column service-table__cost service-table__column--mobile-break">
								Order Value (Excluding VAT)
							</td>

							<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right service-table__cost">
								{this.renderPrice(this.state.totalOrder, 1)}
							</td>
						</tr>
					</tbody>
				</table>

				<button
					type="button"
					className="button button--negative"
					onClick={this.handleBackButton}>
					Go Back
				</button>

				<button
					type="button"
					className="button"
					disabled={!packSelected || (promoCode && !promoCodeValid) || this.state.quoteButtonDisabled}
					// disabled={!packSelected || !subscriptionSelected || (promoCode && !promoCodeValid)}
					// disabled={!this.state.packSelected || (!this.state.subscriptionSelected && !this.state.promoCodeValid)}
					onClick={this.handleConfirmButton}>
					Get Quote
				</button>
			</div>
		);

	}

	renderServiceItems = () => {
		const {
			packSelected,
			displayServiceItems,
			conversionRate,
		} = this.state;

		const {
			currency,
			locale,
			SpotliteClientCatalogue,
		} = this.props;

		const paymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;

		if (packSelected && packSelected.Jurisdictions[0].LinesOfBusiness[0].Services && displayServiceItems) {
			return (
				<ServicePackItems
					ref={(ref) => { this.serviceItemSection = ref; }}
					currency={currency}
					locale={locale}
					packSelected={packSelected}
					paymentMethods={paymentMethods}
					highlightAll={this.highlightAll}
					validateNumber={this.validateNumber}
					isSubscriptionsVisible={this.state.isSubscriptionsVisible}
					handleServicePackItemChange={this.handleServicePackItemChange}
					// handleSubscriptionButton={this.displaySubscription}
					// handleContinue={this.handleContinue}
					// onCatalogueBack={this.handleBackButton}
					onServiceBack={this.handleServiceBackButton}
					conversionRate={conversionRate}
				/>
			);
		}

		return null;
	};

	renderCarryOverPrices = (price) => {
		const {
			currency,
			locale
		} = this.props;

		return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price).replace(/\.00/g, '');
	};

	renderPrice = (price, noOfUnits) => {
		const {
			currency,
			locale
		} = this.props;

		if (noOfUnits === null || typeof noOfUnits === 'undefined')
			noOfUnits = 0;

		return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price * noOfUnits).replace(/\.00/g, '');
	};

	renderPaymentOptions = () => {

		const {
			packSelected,
		} = this.state;

		if (packSelected) {

			return (
				<div
					id="services"
					className="subscription_section"
					ref={(ref) => { this.serviceItemSection = ref; }}>

					{/* {this.renderServiceItems()} */}

					{this.renderSubscriptionsButton()}

					<br />
					{this.renderServiceSubscriptions()}

					<br />
					{this.renderAddOn()}

					<br />
					<br />
					{this.renderPromotions()}

				</div>
			)
		}

		return null;
	};

	priceSelected(...args) {
		//console.log(e, e.currentTarget);
		//console.log(/*args[2],*/ args[0], args[1]);

		var i, tablinks;
		tablinks = document.getElementsByClassName("alt");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].className = tablinks[i].className.replace(" alt--active", "");
		}

		//console.log(tablinks[0])

		args[1].currentTarget.className += " alt--active";

		//Check conversion rate
		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		const {
			packSelected,
			promoSelected,
			totalSubscriptionCost,
			totalAddOnsCost
		} = this.state;

	
		// const paymentMethods = SpotliteClientCatalogue.Currencies[currency].PaymentMethods;
		
		// console.log(paymentMethods)
		// console.log(args[0], args[1])

		let conversionRate = args[0][currency].CurrencyCreditConversionRate;
		let packPrice = args[0][currency].StandardUnitPriceCurrency;

		// Deep copy packSelected to prevent state mutation
		let copyOfPackSelected = deepCopy(packSelected);

		// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		//Loop through all serivces
		let totalCreditAmount = 0;

		let services = [];

		services.push({
			ServiceID: args[0].ServiceID,
			Type: args[0].Type,
			Name: args[0].Name,
			ServiceShortDescription: args[0].ServiceShortDescription,
			ServiceDescription: args[0].ServiceDescription,
			FreeService: args[0].FreeService,
			Credits: args[0].StandardUnitPriceCredits,
			NumberOfUnits: 1,
			CreditPrice: 1,
			StandardUnitPriceCredits: args[0].StandardUnitPriceCredits,
			DefaultTaskUnits: args[0].DefaultTaskUnits,
			VAT: args[0].VAT,
			PriceExclTax: packPrice,
			PriceInclTax: parseFloat((packPrice + (packPrice * taxPercentage)).toFixed(2)),
			Tax: parseFloat((packPrice * taxPercentage).toFixed(2)),
		});

		// console.log(services, conversionRate);

		totalCreditAmount = args[0].StandardUnitPriceCredits;

		let total = packPrice;
		total = (total) + (totalSubscriptionCost);
		total = (total) + (totalAddOnsCost);

		let isDisabled = this.handleDisableQuoteButton(total);

		let totalInclTax = (total) + (total * taxPercentage);

		// If Promotion Code input, there may be free credits to add to the total credit amount
		if (promoSelected) {
			totalCreditAmount += promoSelected.Credits ? promoSelected.Credits : 0;
		}

		this.setState({
			packSelected: copyOfPackSelected,
			totalCostServicePackItems: packPrice,
			totalOrder: total,
			totalOrderInclTax: totalInclTax,
			numberOfCredits: totalCreditAmount,
			conversionRate: conversionRate,
			servicesData: services,
			quoteButtonDisabled: isDisabled,
		});

	}

	learnMore(...args) {
		// console.log(args[0]);
		this.setState({learnMoreModalOpen: true, ServiceID: args[0].ServiceID});
	}

	learnMoreModalClosed = () => {
		this.setState({learnMoreModalOpen: false});
	}

	renderLearnMoreTable() {

		const {
			packSelected,
			displayServiceItems,
			ServiceID,
			learnMoreModalOpen
		} = this.state;

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		const paymentMethods = SpotliteClientCatalogue.Currencies[currency].PaymentMethods;

		var search;

		if (packSelected && packSelected.Jurisdictions[0].LinesOfBusiness[0].Services && displayServiceItems) {
			var services = packSelected.Jurisdictions[0].LinesOfBusiness[0].Services;
		
			search = services.find((service) => service.ServiceID === ServiceID);
			//console.log(search);
		}

		return (
			<Modal
			open={learnMoreModalOpen}
			onClose={this.learnMoreModalClosed}
			key="discountModalText"
			>
				{search !== undefined ?
				<>

				<h2>{search.Name}</h2>
				<br/>
				<div>
				{search.ServiceDescription}
				</div>
				<br/>
				<table className="alt_table">
					<thead>
						<tr>
							<th></th>
							<th>1</th>
							<th>2</th>
							<th>3</th>
							<th>4</th>
							<th>5</th>
						</tr>
					</thead>
					<tbody className="alt_table_body">
						<tr>
							<td>Spotlite Fee</td>
							<td>1</td>
							<td>2</td>
							<td>3</td>
							<td>4</td>
							<td>5</td>
						</tr>
					</tbody>
				</table>


				{/* <ul className="subscription_list">
					
				{
					paymentMethods.CreditTypes.map(item => {
						//console.log(search.StandardUnitPriceCredits, item[currency].CurrencyCreditConversionRate);
						return <li className="className="subscription_list_element>
							{search.StandardUnitPriceCredits * item[currency].CurrencyCreditConversionRate}
							</li>
					})
				}
				</ul> */}
				</>
				: null
				}

			</Modal>
		);
	}




	renderCreditPacks = () => {
		
		const {
			packSelected,
			displayServiceItems,
			servicesData,
		} = this.state;

		const {
			currency,
			locale,
			SpotliteClientCatalogue,
		} = this.props;

		const creditTypes = SpotliteClientCatalogue.Currencies[currency].PaymentMethods.CreditTypes;

		// Set to -1 to ensure no match if needed
		var creditPackIndex = -1;

		if(servicesData !== undefined) {
			if(servicesData.length !== 0){
				creditPackIndex = creditTypes.findIndex((item) => {			
					// console.log(servicesData[0].StandardUnitPriceCredits, item.StandardUnitPriceCredits);
					return servicesData[0].StandardUnitPriceCredits === item.StandardUnitPriceCredits; 
				});
			}
		}

		if (packSelected && packSelected.Jurisdictions[0].LinesOfBusiness[0].Services && displayServiceItems) {
			return (
				<>
				<br/>
				<div className="alt_group" role="group" aria-label="First group">
					{/* <div className="alt_group_back">Back</div> */}
					<div className="alt_group_title">Select a Credit Pack</div>
				{creditTypes.map((option, index) => {
					// console.log(index, option);
					return (
						<button 
							id={`rad${index}`} 
							key={`key${index}`} 
							value={option[currency].StandardUnitPriceCurrency} 
							type="button" 
							className={index === creditPackIndex ? "alt alt--active" : "alt"}
							onClick={this.priceSelected.bind(this, option)}>
							{/*onClick={this.priceSelected.bind(this, option.StandardUnitPriceCredits, option[currency].StandardUnitPriceCurrency)}>*/}
								{new Intl.NumberFormat(locale, { style: 'currency', currency: currency })
								.format(option[currency].StandardUnitPriceCurrency).replace(/\.00/g, '')}
								<div>{`${option.StandardUnitPriceCredits} Credits`}</div>
						</button>
					);
				})}
				</div>
				</>
			);
			
		}
		return null
	}

	renderServicePacks = () => {

		const {
			packSelected,
			displayServiceItems
		} = this.state;

		const {
			currency,
			locale,
			SpotliteClientCatalogue,
		} = this.props;

		if (packSelected && packSelected.Jurisdictions[0].LinesOfBusiness[0].Services && displayServiceItems) {
			var services = packSelected.Jurisdictions[0].LinesOfBusiness[0].Services;

			return (
				<>
				<br/>
				<div>
					The cost to check your applicants will vary depending on the type of check required. Below are the costs for each type of check offered in this pack.<br/>Checks are effectively discounted based on the volume of credits purchased.
				</div>
				<br/>
				<div className="refactoredServiceGroup">
					{services.map((option, index) => {
						
						// console.log(option);

						return (
							<div 
							id={`service_id_${index}`} 
							key={`service_key${index}`} 
							role="group"
							className="creditPackContainer">
								
								<div className="">
									<div className="creditPackHeader">
									<p>{option.Name}</p>
									</div>
									<div className="creditPackShortDescription">{option.ServiceShortDescription}</div>
									<div className="creditPackPrice"><b>{new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2 })
									.format(option.StandardUnitPriceCredits * this.state.conversionRate)}</b></div>
									<div className="creditPackVAT">excl. VAT</div>
									
								</div> 

								{/* <div className="card_button_container">
									<button
										className="learnMoreButton"
										type="button"
										value={option}
										onClick={this.learnMore.bind(this, option)}
										>
											<span>Learn More</span>
									</button>
               					</div> */}
				
							</div>
						);

					})}
				</div>
				</>
			);
		}
		return null;

	}

	render() {

		return (
			<div>

				{this.renderTitle()}

				{this.renderLearnMoreTable()}

				{this.renderCreditPacks()}

				{this.renderServicePacks()}

				{this.renderServices()}

				{this.renderPaymentOptions()}

			</div>
		);
	};

	renderTitle = () => {
		if (this.state.isSubscriptionsVisible) {
			return (
				<Headline componentType="h3">
					Please select the subscription you would like to order
				</Headline>
			);
		} else if (!this.state.isSubscriptionsVisible && this.state.displayServiceItems) {

			return(
				<>

				<div className="services_header">

				<div className="services_header_button"
					// type="button"
					onClick={() => this.handleServiceBackButton()}>
					<span>Back</span>
				</div>

				<div className="services_header_element">
					<Headline componentType="h4">
						Pricing
					</Headline>
				</div>

				<div/>
				</div>
				<br/>
				</>
			);

		} else {
			return (
				<Headline componentType="h3">
					Please select a pack
				</Headline>
			);
		}
	}

	handleDisableQuoteButton = (value) => {

		const {
			SpotliteClientCatalogue,
		} = this.props;

		//Grab Payment Methods and look against index 0
		const paymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;
		let creditType = paymentMethods.CreditTypes[0];

		//console.log(creditType.StandardUnitPriceCredits, value);

		//Returns 
		return !(value >= creditType.StandardUnitPriceCredits);

	}

	// Handle Subscriptions Switch
	handleSubscriptionSwitch = (selectedIndex) => {

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		const {
			totalCostServicePackItems,
			totalAddOnsCost,
			isAnnual
		} = this.state;

		let subscriptions = deepCopy(this.state.subscriptionSelected);
		// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		let subscriptionsData = [];
		let updatedSubscriptionCost = 0;

		subscriptions.forEach((item, index) => {
			if (index !== selectedIndex) {
				item.NumberOfUnits = 0;
			} else {
				item.NumberOfUnits = item.DefaultAgentsAllowed;

			

				//Calculate new price
				//let price = isAnnual ? (item[currency].StandardUnitPriceCurrency - ((item[currency].StandardUnitPriceCurrency / 12) * item.TrialPeriodTimeUnits)) : parseFloat(item[currency].PremiumUnitPriceCurrency / 12);

				let unitPrice = isAnnual ? item[currency].StandardUnitPriceCurrency : item[currency].PremiumUnitPriceCurrency;

				let annualPrice = isAnnual ? unitPrice * (12 - item.TrialPeriodTimeUnits) : (unitPrice * 12);

				let PriceExclTax = parseFloat(annualPrice * item.NumberOfUnits);
				let PriceInclTax = parseFloat(PriceExclTax + (PriceExclTax * taxPercentage));
				let Tax = parseFloat((PriceExclTax * taxPercentage).toFixed(2));

				updatedSubscriptionCost = updatedSubscriptionCost += PriceExclTax;

				// Update the subscriptionData
				subscriptionsData.push({
					Name: item.Name,
					Type: item.Type,
					ShortDescription: item.ShortDescription,
					Description: item.Description,
					TimeType: item.TimeType,
					ServiceDurationTimeUnit: item.ServiceDurationTimeUnit,
					FreeService: item.FreeService,
					FreeUnits: item.FreeUnits,
					FreeCredits: item.FreeCredits,
					TrialPeriodTimeUnits: item.TrialPeriodTimeUnits,
					TrialPeriodType: item.TrialPeriodType,
					MinUnits: item.MinUnits,
					MaxUnits: item.MaxUnits,
					NumberOfUnits: item.NumberOfUnits,
					StandardUnitPriceCredits: item.StandardUnitPriceCredits,
					DefaultThresholdCreditLevel: item.DefaultThresholdCreditLevel,
					DefaultAgentsAllowed: item.DefaultAgentsAllowed,
					DefaultApplicantsAllowed: item.DefaultApplicantsAllowed,
					DefaultOrderUnits: item.DefaultOrderUnits,
					ClientCatalogueID: item.ClientCatalogueID,
					SystemControlLevel: item.SystemControlLevel,
					RBACSettings: {
						AgentAppEnabled: item.SetAgentAppEnabled,
						AgentCRMEnabled: item.SetAgentCRMEnabled,
						ApplicantAppEnabled: item.SetApplicantAppEnabled,
						ApplicantCRMEnabled: item.SetApplicantCRMEnabled,
						SafeGuardenEnabled: item.SetSafeGuardenEnabled
					},
					BillingCalculationMethod: item.BillingCalculationMethod,
					BillingFrequency: item.BillingFrequency,
					VAT: item.VAT,
					PriceExclTax: parseFloat(PriceExclTax.toFixed(2)),
					PriceInclTax: parseFloat(PriceInclTax.toFixed(2)),
					Tax: Tax,
					Credits: (isAnnual ? (item.FreeCredits * item.NumberOfUnits) : ((item.FreeCredits * item.NumberOfUnits) / 12)),
					isAnnual: isAnnual,
				});

				this.setDate(isAnnual, item.TrialPeriodTimeUnits);
			}
		});

		let total = updatedSubscriptionCost;

		total = (total) + (totalCostServicePackItems);
		total = (total) + (totalAddOnsCost);

		let isDisable = this.handleDisableQuoteButton(total);

		//console.log(isDisable);

		let totalInclTax = (total) + (total * taxPercentage);

		this.setState({
			subscriptionSelected: subscriptions,
			subscriptionsData: subscriptionsData,
			totalSubscriptionCost: updatedSubscriptionCost, //reset
			totalOrder: total,
			totalOrderInclTax: totalInclTax,
			index: selectedIndex,
			quoteButtonDisabled: isDisable,
			isAnnual: isAnnual
		});

	}

	// Handle Subscriptions
	handleSubscriptionChange = (evt, selectedIndex) => {

		const {
			totalCostServicePackItems,
			totalAddOnsCost,
			isAnnual
		} = this.state;

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;


		let subscriptions = deepCopy(this.state.subscriptionSelected);

		let value = evt.target.value;

		if (value < subscriptions[selectedIndex].MinUnits) {
			value = subscriptions[selectedIndex].MinUnits;
		}

		if (value > subscriptions[selectedIndex].MaxUnits) {
			value = subscriptions[selectedIndex].MaxUnits;
		}

	// 	//Check Subscription has Free Service

		if (subscriptions[selectedIndex].FreeService) {
			let NumberOfUnits = parseInt(value, 10) - subscriptions[selectedIndex].FreeUnits;

			if (Math.sign(NumberOfUnits) === -1) {
				subscriptions[selectedIndex].NumberOfUnits = 0;
			} else {
				subscriptions[selectedIndex].NumberOfUnits = NumberOfUnits;
			}
		} else {
			subscriptions[selectedIndex].NumberOfUnits = parseInt(value, 10);
		}

	 	// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		let subscriptionsData = [];
		let updatedSubscriptionCost = 0;

		subscriptions.forEach((item, index) => {

			let unitPrice = isAnnual ? item[currency].StandardUnitPriceCurrency : item[currency].PremiumUnitPriceCurrency;

			let annualPrice = isAnnual ? unitPrice * (12 - item.TrialPeriodTimeUnits) : (unitPrice * 12);

			//let price = isAnnual ? (item[currency].StandardUnitPriceCurrency - ((item[currency].StandardUnitPriceCurrency / 12) * item.TrialPeriodTimeUnits)) : parseFloat(item[currency].PremiumUnitPriceCurrency / 12);

			//console.log(selectedIndex, index)
			if (index !== selectedIndex) {
				item.NumberOfUnits = 0;
			} else {

			//if (!isNaN(item.NumberOfUnits) || !item.NumberOfUnits === '') {

				//let minAgent = item.DefaultAgentsAllowed;
				// let minAgent = item.NumberOfUnits;

				// if(minAgent == 0) {
				// 	minAgent = 1;
				// }

				let PriceExclTax = parseFloat(annualPrice * item.NumberOfUnits);
				let PriceInclTax = parseFloat(PriceExclTax + (PriceExclTax * taxPercentage));
				let Tax = parseFloat((PriceExclTax * taxPercentage).toFixed(2));

				//console.log(item.NumberOfUnits, PriceExclTax);

				updatedSubscriptionCost = updatedSubscriptionCost += PriceExclTax;

				subscriptionsData.push({
					Name: item.Name,
					Type: item.Type,
					ShortDescription: item.ShortDescription,
					Description: item.Description,
					TimeType: item.TimeType,
					ServiceDurationTimeUnit: item.ServiceDurationTimeUnit,
					FreeService: item.FreeService,
					FreeUnits: item.FreeUnits,
					FreeCredits: item.FreeCredits,
					TrialPeriodTimeUnits: item.TrialPeriodTimeUnits,
					TrialPeriodType: item.TrialPeriodType,
					MinUnits: item.MinUnits,
					MaxUnits: item.MaxUnits,
					NumberOfUnits: item.NumberOfUnits,
					StandardUnitPriceCredits: item.StandardUnitPriceCredits,
					DefaultThresholdCreditLevel: item.DefaultThresholdCreditLevel,
					DefaultAgentsAllowed: item.DefaultAgentsAllowed,
					DefaultApplicantsAllowed: item.DefaultApplicantsAllowed,
					DefaultOrderUnits: item.DefaultOrderUnits,
					ClientCatalogueID: item.ClientCatalogueID,
					SystemControlLevel: item.SystemControlLevel,
					RBACSettings: {
						AgentAppEnabled: item.SetAgentAppEnabled,
						AgentCRMEnabled: item.SetAgentCRMEnabled,
						ApplicantAppEnabled: item.SetApplicantAppEnabled,
						ApplicantCRMEnabled: item.SetApplicantCRMEnabled,
						SafeGuardenEnabled: item.SetSafeGuardenEnabled
					},
					BillingCalculationMethod: item.BillingCalculationMethod,
					BillingFrequency: item.BillingFrequency,
					VAT: item.VAT,
					PriceExclTax: parseFloat(PriceExclTax.toFixed(2)),
					PriceInclTax: parseFloat(PriceInclTax.toFixed(2)),
					Tax: Tax,
					//Credits: (isAnnual ? (item.FreeCredits * item.NumberOfUnits) : ((item.FreeCredits * item.NumberOfUnits) / 12)),
					Credits: (isAnnual ? (item.FreeCredits * item.NumberOfUnits) : 0),
					isAnnual: isAnnual,
				});

				this.setDate(isAnnual, item.TrialPeriodTimeUnits)
			}
		});

		let total = updatedSubscriptionCost;

		total = (total) + (totalCostServicePackItems);
		total = (total) + (totalAddOnsCost);

		let totalInclTax = (total) + (total * taxPercentage);

		//subscriptionSelected: subscriptions,
		//subscriptionsData: subscriptionsData,

		this.setState({
			subscriptionSelected: deepCopy(subscriptions),
			totalSubscriptionCost: updatedSubscriptionCost,
			totalOrder: total,
			totalOrderInclTax: totalInclTax,
			isAnnual: isAnnual,
			subscriptionsData: subscriptionsData,
		});

	};

	handleFreeCredits = () => {
		
		var units = 1;
		var freeCredits = 0;

		this.state.subscriptionsData.forEach(item => {
			//console.log(item.Name, item.NumberOfUnits);
			units = item.NumberOfUnits; 
			freeCredits = this.state.isAnnual ? (item.FreeCredits * units) : 0;
		});
		
		return freeCredits;
	}

	// Handle Services
	handleServicePackItemChange = (evt, index) => {

		const {
			packSelected,
			promoSelected,
			totalSubscriptionCost,
			totalAddOnsCost
		} = this.state;

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		let value = parseInt(evt.target.value, 10);

		//Prevent a negative value being added
		if (value < 0)
			value = 0;

		if (value > 10000)
			value = 10000;

		// Deep copy packSelected to prevent state mutation
		let copyOfPackSelected = deepCopy(packSelected);

		// Assign new value to array element at property
		copyOfPackSelected.Jurisdictions[0].LinesOfBusiness[0].Services[index].NumberOfUnits = value;

		// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		//Loop through all serivces
		let totalCreditAmount = 0;

		let services = [];

		copyOfPackSelected.Jurisdictions[0].LinesOfBusiness[0].Services.forEach(item => {

			let credits = item.StandardUnitPriceCredits * item.NumberOfUnits;

			if (isNaN(credits))
				credits = 0;

			totalCreditAmount = totalCreditAmount + credits;

			services.push({
				ServiceID: item.ServiceID,
				Type: item.Type,
				Name: item.Name,
				ServiceShortDescription: item.ServiceShortDescription,
				ServiceDescription: item.ServiceDescription,
				FreeService: item.FreeService,
				Credits: credits,
				NumberOfUnits: item.NumberOfUnits,
				CreditPrice: item.StandardUnitPriceCredits,
				StandardUnitPriceCredits: item.StandardUnitPriceCredits,
				DefaultTaskUnits: item.DefaultTaskUnits,
				VAT: item.VAT,
				PriceExclTax: 0,
				PriceInclTax: 0,
				Tax: 0,
			});
		});

		let conversionRate = this.getConversionRate(totalCreditAmount);

		services.forEach(item => {
			item.PriceExclTax = (item.Credits * conversionRate);
			item.PriceInclTax = (item.PriceExclTax + (item.PriceExclTax * taxPercentage));
			item.Tax = parseFloat((item.PriceExclTax * taxPercentage).toFixed(2));
			item.CreditPrice = (item.CreditPrice * conversionRate);

			item.PriceExclTax = parseFloat(item.PriceExclTax.toFixed(2));
			item.PriceInclTax = parseFloat(item.PriceInclTax.toFixed(2));

		});

		// Originally calculated line item cost with possibility of tax for each line
		let updatedCostServicePackItems = totalCreditAmount * conversionRate;

		let total = updatedCostServicePackItems;
		total = (total) + (totalSubscriptionCost);
		total = (total) + (totalAddOnsCost);

		let isDisabled = this.handleDisableQuoteButton(total);

		let totalInclTax = (total) + (total * taxPercentage);

		// If Promotion Code input, there may be free credits to add to the total credit amount
		if (promoSelected) {
			totalCreditAmount += promoSelected.Credits ? promoSelected.Credits : 0;
		}

		this.setState({
			packSelected: copyOfPackSelected,
			totalCostServicePackItems: updatedCostServicePackItems,
			totalOrder: total,
			totalOrderInclTax: totalInclTax,
			numberOfCredits: totalCreditAmount,
			conversionRate: conversionRate,
			servicesData: services,
			quoteButtonDisabled: isDisabled,
		});
	};

	renderMinimumPurchaseLabel() {

		const {
			SpotliteClientCatalogue,
		} = this.props;

		const paymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;

		if (paymentMethods.CreditTypes != null && paymentMethods.CreditTypes.length > 0) {

			let creditType = paymentMethods.CreditTypes[0];

			//Check credit type is Minimum Purchase
			if (creditType.Name === "Minimum Purchase") {
				//Set minimum purcase level
				return (
					<span className="quote_warning">
						{this.state.quoteButtonDisabled ?
							`A minimum purchase of ${new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: this.props.currency })
								.format(creditType.StandardUnitPriceCredits).replace(/\.00/g, '')} must be made.` : null}
					</span>
				);
			} else {
				return <></>
			}
		} else {
			return <></>
		}

	}

	getConversionRate = (totalCredits) => {

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		if (isNaN(totalCredits))
			totalCredits = 0;

		const paymentMethods = SpotliteClientCatalogue.Currencies.GBP.PaymentMethods;

		let subIndex = paymentMethods.CreditTypes.findIndex((item, i) => {
			return (
				//first element unique condition
				((i === 0 && totalCredits < item.StandardUnitPriceCredits)
					|| (i === 0
						&& totalCredits >= item.StandardUnitPriceCredits
						&& i + 1 <= paymentMethods.CreditTypes.length - 1
						&& totalCredits < paymentMethods.CreditTypes[i + 1].StandardUnitPriceCredits))

				//main check
				|| (totalCredits >= item.StandardUnitPriceCredits
					&& i + 1 <= paymentMethods.CreditTypes.length - 1
					&& totalCredits < paymentMethods.CreditTypes[i + 1].StandardUnitPriceCredits)
				//last element unique condition
				|| (i === paymentMethods.CreditTypes.length - 1
					&& totalCredits >= item.StandardUnitPriceCredits)
			)
		}
		);

		let creditType = paymentMethods.CreditTypes[subIndex];
		//console.log(paymentMethods.CreditTypes, subIndex);
		let conversionRate = creditType[currency].CurrencyCreditConversionRate;

		// let quoteButtonDisabled = true;

		// //Disable or enable quote button if conversion rate is 1 and total credits is less than the StandardUnitPriceCredits
		// if(conversionRate == 1){
		// 	quoteButtonDisabled = this.handleDisableQuoteButton(totalCredits);
		// } else {
		// 	quoteButtonDisabled = false;
		// }

		// this.setState({
		// 	quoteButtonDisabled: quoteButtonDisabled,

		// });

		return conversionRate;

	}

	setDate = (isAnnual, TrialTime) => {

		//Set Start Date
		let startDate = new Date();
		let start_dd = String(startDate.getDate()).padStart(2, '0');
		let start_mm = String(startDate.getMonth() + 1).padStart(2, '0');
		let start_yyyy = startDate.getFullYear();

		startDate = start_yyyy + '-' + start_mm + '-' + start_dd;

		//Set End Date	
		let endDate = new Date();
		let dueDate = new Date();
		//if standard give 12 months else give 1
		if (isAnnual) {
			endDate.setMonth(endDate.getMonth() + 12);
			dueDate.setMonth(dueDate.getMonth() + (12 - TrialTime));
		} else {
			endDate.setMonth(endDate.getMonth() + 1);
			dueDate.setMonth(dueDate.getMonth() + 1);
		}

		let end_dd = String(endDate.getDate()).padStart(2, '0');
		let end_mm = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
		let end_yyyy = endDate.getFullYear();

		endDate = end_yyyy + '-' + end_mm + '-' + end_dd;

		let due_dd = String(dueDate.getDate()).padStart(2, '0');
		let due_mm = String(dueDate.getMonth() + 1).padStart(2, '0'); //January is 0!
		let due_yyyy = dueDate.getFullYear();

		dueDate = due_yyyy + '-' + due_mm + '-' + due_dd;

		this.setState({ spotliteStartDate: startDate, spotliteEndDate: endDate, spotliteDueDate: dueDate, billingFrequency: isAnnual ? "Annually" : "Monthly" })

	}

	// Calculate Current Order Total
	calculateCurrentOrderTotal = (isAnnual) => {

		const {
			totalCostServicePackItems,
			totalAddOnsCost,
		} = this.state;

		const {
			currency,
			SpotliteClientCatalogue,
		} = this.props;

		let subscriptions = deepCopy(this.state.subscriptionSelected);

		// Grab Tax Percentage
		const taxPercentage = SpotliteClientCatalogue.Currencies[currency].TaxPercentage;

		let subscriptionsData = [];
		let updatedSubscriptionCost = 0;

		subscriptions.forEach((item, index) => {

			if (index !== this.state.index) {
				item.NumberOfUnits = 0;
			} else {

				let unitPrice = isAnnual ? item[currency].StandardUnitPriceCurrency : item[currency].PremiumUnitPriceCurrency;

				let annualPrice = isAnnual ? unitPrice * (12 - item.TrialPeriodTimeUnits) : (unitPrice * 12);

				//console.log("number of units: ", item.NumberOfUnits, " Annual price is: ", annualPrice);

				if (isNaN(annualPrice))
					annualPrice = 0;

				let PriceExclTax = parseFloat(annualPrice * item.NumberOfUnits);
				let PriceInclTax = parseFloat((PriceExclTax + (PriceExclTax * taxPercentage)));

				updatedSubscriptionCost = updatedSubscriptionCost += PriceExclTax;

				// Update the subscriptionData
				subscriptionsData.push({
					Name: item.Name,
					Type: item.Type,
					ShortDescription: item.ShortDescription,
					Description: item.Description,
					TimeType: item.TimeType,
					ServiceDurationTimeUnit: item.ServiceDurationTimeUnit,
					FreeService: item.FreeService,
					FreeUnits: item.FreeUnits,
					FreeCredits: item.FreeCredits,
					TrialPeriodTimeUnits: item.TrialPeriodTimeUnits,
					TrialPeriodType: item.TrialPeriodType,
					MinUnits: item.MinUnits,
					MaxUnits: item.MaxUnits,
					NumberOfUnits: item.NumberOfUnits,
					StandardUnitPriceCredits: item.StandardUnitPriceCredits,
					DefaultThresholdCreditLevel: item.DefaultThresholdCreditLevel,
					DefaultAgentsAllowed: item.DefaultAgentsAllowed,
					DefaultApplicantsAllowed: item.DefaultApplicantsAllowed,
					DefaultOrderUnits: item.DefaultOrderUnits,
					ClientCatalogueID: item.ClientCatalogueID,
					SystemControlLevel: item.SystemControlLevel,
					RBACSettings: {
						AgentAppEnabled: item.SetAgentAppEnabled,
						AgentCRMEnabled: item.SetAgentCRMEnabled,
						ApplicantAppEnabled: item.SetApplicantAppEnabled,
						ApplicantCRMEnabled: item.SetApplicantCRMEnabled,
						SafeGuardenEnabled: item.SetSafeGuardenEnabled
					},
					BillingCalculationMethod: item.BillingCalculationMethod,
					BillingFrequency: item.BillingFrequency,
					VAT: item.VAT,
					PriceExclTax: parseFloat(PriceExclTax.toFixed(2)),
					PriceInclTax: parseFloat(PriceInclTax.toFixed(2)),
					Tax: parseFloat((PriceExclTax * taxPercentage).toFixed(2)),
					//Credits: (isAnnual ? (item.FreeCredits * item.NumberOfUnits) : ((item.FreeCredits * item.NumberOfUnits) / 12)),
					Credits: isAnnual ? (item.FreeCredits * item.NumberOfUnits) : 0,
					isAnnual: isAnnual,
				});

				this.setDate(isAnnual, item.TrialPeriodTimeUnits);
			}
		});

		let total = updatedSubscriptionCost;
		total = (total) + (totalCostServicePackItems);
		total = (total) + (totalAddOnsCost);

		let isDisabled = this.handleDisableQuoteButton(total);

		let totalInclTax = (total) + (total * taxPercentage);

		this.setState({
			subscriptionSelected: deepCopy(subscriptions),
			totalSubscriptionCost: updatedSubscriptionCost,
			totalOrder: total,
			totalOrderInclTax: totalInclTax,
			isAnnual: isAnnual,
			subscriptionsData: subscriptionsData,
			quoteButtonDisabled: isDisabled,
		});

	}

}
