import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Headline from '../Headline/Headline';

class BusinessSearchConfirm extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	renderAddress() {
		const { registered_address } = this.props.selectedBusiness;

		return (
			<dd>
				{
					Object.keys(registered_address).map((line, index) => {
						// const addressLine = this.props.selectedBusiness.BusinessLocation[line];
						const addressLine = this.props.selectedBusiness.registered_address[line];

						if (addressLine) {
							return (
								<span key={index}>
									{addressLine}<br />
								</span>
							)
						}

						return null;
					})
				}
			</dd>
		);
	}

	confirmBusinessSelection(e) {
		this.props.updateConfirmBusinessSelection(true);
	}

	render() {
		const {
			componentType,
			className,
			selectedBusiness,
		} = this.props;

		const {error} = this.state;

		const Element = componentType;
		const classNames = className;

		// if (!selectedBusiness || !selectedBusiness.BusinessRef || !selectedBusiness.Name) {
		if (!selectedBusiness || !selectedBusiness.company_number || !selectedBusiness.name) {
			return (
				<Element className={classNames}>
					<Headline>
						Sorry, there seems to be a problem with the information we have. Please try a new search.
					</Headline>

					<button
						className="button button--wide"
						type="button"
					>
						Go back
					</button>
				</Element>
			)
		}

		return (
			<Element className={classNames}>
				<Headline>
					{/* Please check the details for<br /> */}
					{/*selectedBusiness.Name*/}
					{selectedBusiness.name}
				</Headline>

				{/* <p className="terms">To fully activate your account we will check your registration with the Information Commissioners Office (ICO).</p>
				<p className="terms">Please confirm your registered address.</p> */}

				<div className="section">
					<dl className="definition-list">

						<dt>Company Number</dt>
						{/* <dd>{ selectedBusiness.BusinessRef }</dd> */}
						<dd>{ selectedBusiness.company_number }</dd>

						<dt>Registered Address</dt>
						{ this.renderAddress() }

					</dl>
				</div>

				<fieldset className="form__fieldset">
					<div className="form__message form__message--error">
						{error}
					</div>
				</fieldset>

				<button
					className="button button--negative"
					type="button"
					value="GoBack"
					onClick={this.props.handleBackButton}
				>
					Go Back
				</button>

				<button
					className="button"
					type="button"
					onClick={((e) => this.confirmBusinessSelection(e))}
				>
					Confirm
				</button>
			</Element>
		);
	}
}

export default BusinessSearchConfirm;