import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from '../Modal/Modal';
import './style/AutoModal.css';

export default class AutoModal extends PureComponent {
	static propTypes = {
		icon: PropTypes.string,
		iconClassName: PropTypes.string
	};

	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	state = {
		open: false
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	handleOpen = event => {
		event.preventDefault();
		
		this.setState({
			open: true
		});
	};

	renderCTA = () => {
		const {
			icon,
			text
		} = this.props;

		const iconClassNames = `icon icon--${this.props.icon} ${this.props.iconClassName}`

		if (icon) {
			return (
				<span
					className={iconClassNames}
					onClick={this.handleOpen}
				/>
			)
		}

		if (text) {
			return (
				<span
					className="automodal__text"
					onClick={this.handleOpen}
				>
					{ text }
				</span>
			)
		}

		return null;
	};

	render() {
		const {
			children
		} = this.props;

		return (
			<span className="automodal">
				{ this.renderCTA() }

				<Modal
					open={this.state.open}
					onClose={this.handleClose}
				>
					{children}
				</Modal>
            </span>
		);
	}
}
