import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Logo extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string,
		className: PropTypes.string,
	};

	static defaultProps = {
		componentType: 'div'
	};

	render() {
		const {
			componentType,
			className,
			...others
		} = this.props;
		const Element = componentType;
		const classNames = `logo ${className}`;

		return (<img className={classNames} {...others} src='/assets/img/spotlite_business_services.png'/>);

		return (
            <Element className={classNames} {...others}>
				<h1 className="logo__text">Spotlite</h1>
            </Element>
		);
	}
}
