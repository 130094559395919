import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './style/Footer.css';

export default class Footer extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'footer'
	};

	render() {
		const {
			componentType,
			className,
			...others
		} = this.props;

		const Element = componentType;
		const classNames = `footer ${className}`;

		return (
			<Element className={classNames} {...others}>
				<div className="wrapper wrapper--single">
					<div className="footer__copyright">
						<ul className="footer__list">
							<li className="footer__list__item">
								<a href="https://spotlite.services/terms/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
							</li>
							<li className="footer__list__item">
								<a href="https://spotlite.services/cookie-policy/" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
							</li>
							<li className="footer__list__item">
								<a href="https://spotlite.services/user-privacy-policy/" target="_blank" rel="noopener noreferrer">Agent Privacy Policy</a>
							</li>
							<li className="footer__list__item">
								<a href="https://spotlite.services/applicant-privacy-policy/" target="_blank" rel="noopener noreferrer">Applicant Privacy Policy</a>
							</li>
						</ul>
						<p>
							Copyright © Spotlite Business Services Limited {new Date().getFullYear()}. All Rights Reserved.<br />
							Company UK Registration Number 10036916
							{/*
							Copyright © <a className="footer__link" href="http://spotlite.services/" target="_blank" rel="noopener noreferrer">Spotlite Management Services Ltd</a> 2020. All Rights Reserved.<br />
							Company UK Registration Number 10036916
							*/}
						</p>
					</div>
				</div>
			</Element>
		);
	}
}
