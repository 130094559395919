import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Phone from 'react-phone-number-input';
// import InputPassword from 'react-password-strength';

// import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import './style/RegisterForm.css';

export default class RegisterForm extends PureComponent {
	static propTypes = {
		componentType: PropTypes.string,
		className: PropTypes.string
	};

	static defaultProps = {
		componentType: 'form'
	};
/*
	handlePasswordChange = passwordState => {
		const error = passwordState.isValid ? null : 'Your password is too simple';
		this.props.updateErrorState(error, 'password', passwordState.password);
	};

	handlePasswordConfirm = event => {
		const error = (event.target.value === this.props.password) ? null : 'Your passwords don’t match';
		this.props.updateErrorState(error, 'passwordConfirm', event.target.value);
	};
*/
	render() {
		const {
			componentType,
			className,
			errors,
			errorList,
			workInProgress
		} = this.props;

		const Element = componentType;
		const classNames = `register-form form ${(className) ? className : ''}`;

		return (
			<Element name="register" id="register" className={classNames}>
				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="forename">Forename</label>
					<input
						className={"form__input" + (errors.forename ? " form__input--error" : "")}
						type="text"
						id="forename"
						name="forename"
						placeholder="Your forename"
						value={this.props.forename}
						onChange={this.props.onChange}
						onBlur={this.props.onBlurEvent}
					/>
				</fieldset>

				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="middleName">Middle Names</label>
					<input
						className="form__input"
						type="text"
						id="middleName"
						name="middleName"
						placeholder="Any middle names"
						value={this.props.middleName}
						onChange={this.props.onChange}
						onBlur={this.props.onBlurEvent}
					/>
				</fieldset>

				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="surname">Surname</label>
					<input
						className={"form__input" + (errors.surname ? " form__input--error" : "")}
						type="text"
						id="surname"
						name="surname"
						placeholder="Your surname"
						value={this.props.surname}
						onChange={this.props.onChange}
						onBlur={this.props.onBlurEvent}
					/>
				</fieldset>

				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="eMail">Email Address</label>
					<input
						className={"form__input" + (errors.eMail ? " form__input--error" : "")}
						type="email"
						id="email"
						name="eMail"
						placeholder="Enter your Email address"
						value={this.props.eMail}
						onChange={this.props.onChange}
						onBlur={this.props.onEmailBlur}
					/>
				</fieldset>

				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="confirmEmail">Confirm Email Address</label>
					<input
						className={"form__input" + (errors.confirmEmail ? " form__input--error" : "")}
						type="confirmEmail"
						id="confirmEmail"
						name="confirmEmail"
						placeholder="Confirm your Email address"
						value={this.props.confirmEmail}
						onChange={this.props.onChange}
						onBlur={this.props.onEmailBlur}
					/>
				</fieldset>

				{/*
				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="password">Password</label>
					<InputPassword
						className={"form__password" + (errors.password ? " form__input--error" : "")}
						minLength={5}
						minScore={2}
						scoreWords={['too weak', 'too weak', 'good', 'strong', 'stronger']}
						tooShortWord="too short"
						inputProps={{ className: "form__password__input", id: "password", name: "password", placeholder: "Create a password", required: true }}
						changeCallback={this.handlePasswordChange}
					/>
				</fieldset>

				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="passwordConfirm">Retype Password</label>
					<input
						className={"form__input" + (errors.passwordConfirm ? " form__input--error" : "")}
						type="password"
						id="passwordConfirm"
						name="passwordConfirm"
						placeholder="Retype your password"
						onChange={this.handlePasswordConfirm}
					/>
				</fieldset>
				*/}

				<fieldset className="form__fieldset">
					<label className="form__label" htmlFor="phoneNumber">Mobile Phone Number</label>
					<Phone
						className={"form__phone" + (errors.phoneNumber ? " form__input--error" : "")}
						type="tel"
						id="phoneNumber"
						name="phoneNumber"
						country="GB"
						defaultCountry="GB"
						placeholder="Enter your mobile phone number"
						value={this.props.phoneNumber}
						onChange={this.props.onPhoneNoChange}
						onBlur={this.props.onPhoneNoBlur}
					/>
				</fieldset>

				<fieldset className="form__fieldset">
					<div className="form__message">
						We will send you a text message to verify your telephone number 
					</div>
				</fieldset>

				<fieldset className="form__fieldset">
					{
						this.props.errorList ?
							Object.keys(errorList).map((key, index) => {
								return (
									<div className="form__message form__message--error" key={index}>
										{errorList[key]}
									</div>
								)
							})
						: null
					}
				</fieldset>

				<fieldset className="form__fieldset">
					<input
						className="button button--wide"
						type="submit"
						disabled={workInProgress || !this.props.onCanSubmit()}
						value={ this.props.getButtonText() }
						onClick={this.props.onHandleSubmit}
					/>
				</fieldset>

				{/*
				<fieldset className="form__fieldset">
					<div className="form__message">
						By clicking on the Register button to use our services you agree to be bound by the terms of use.
					</div>
				</fieldset>
				*/}
			</Element>
		);
	}
}
