import PropTypes from 'prop-types';
import React, { Component } from 'react';
import config from '../../config';
import { getRequest, postRequest, apiParse } from '../../utils/Common.js';

import Headline from '../Headline/Headline';
import Loader from '../Loader/Loader';

const env = process.env.NODE_ENV || 'development';

class BusinessSearchForm extends Component {
	static propTypes = {
		componentType: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div'
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			workInProgress: false,
			companyName: '',
			companyNumber: '',
			companyNameInProgress: false,
			companyNumberInProgress: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	getSearchResults() {
		// const URI = `${config[env].URL}/search?name=${this.state.searchValue}`;

		// Use BIZIQURL config variable temporary fix ...... 
		// const URI = `${config[env].BIZIQURL}${config[env].BIZIQPORT}/home/search?name=${this.state.searchValue}`;
		const { companyName, companyNumber } = this.state;
		const URI = `${config[env].URL}/company-search`;
		const options = {
			companyName,
			companyNumber
		};
		const credentials = 'omit';
		const headers = { 'appkey': process.env.REACT_APP_APP_KEY };

		// getRequest(URI, credentials, headers)
		return postRequest(URI, options, credentials, headers)
			.then(apiParse)
			.then(response => {
				if (response instanceof Error) {
					throw response;
				}
				if (response.error) {
					throw new Error(response.error)
				}
				this.setState({
					workInProgress: false,
					companyNameInProgress: false,
					companyNumberInProgress: false
				});
				this.props.updateSearchResults(response.results, (companyName || companyNumber));
			})
			.catch(err => {
				let errorMessage = err.message || err;
				this.setState({
					workInProgress: false,
					companyNameInProgress: false,
					companyNumberInProgress: false,
					error: errorMessage
				});
				return errorMessage;
			});
	}

	handleChange = event => {
		let error = this.state.error ? null : this.state.error;

		this.setState({
			[event.target.name]: event.target.value,
			[`${event.target.name}InProgress`]: false,
			error: error,
			workInProgress: false
		})
	};

	handleSubmit = event => {
		event.preventDefault();

		let { companyName, companyNumber } = this.state;

		if (companyName) {
			this.setState({
				companyNameInProgress: true,
				workInProgress: true
			});
			this.getSearchResults();
		} else if (companyNumber) {
			this.setState({
				companyNumberInProgress: true,
				workInProgress: true
			});
			this.getSearchResults();
		} else {
			this.setState({
				error: 'Please input your company / sole trader / partnership name OR number'
			});
		}
		/*
				if (this.state.searchValue) {
					this.setState({
						workInProgress: true
					});
					this.getSearchResults();
				} else {
					this.setState({
						error: 'Please input your company / sole trader / partnership name'
					});
				}
		*/
	};

	getButtonText() {
		return this.state.workInProgress ? "Searching, please Wait..." : "Search"
	}

	render() {
		const {
			componentType,
			className
		} = this.props;

		const { error } = this.state;

		const Element = componentType;
		const classNames = className;

		return (
			<Element className={classNames}>
				<Headline>
					To start, simply enter your company number OR company name
				</Headline>

				<form
					name="companySearch"
					id="companySearch"
					className="form"
					autoComplete="off"
					onSubmit={this.handleSubmit}
				>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="companyName">Company Name</label>
						<input
							className="form__input"
							type="text"
							name="companyName"
							value={this.state.companyName}
							onChange={this.handleChange}
							placeholder="Enter Company Name"
						/>
						<Loader
							className="form__input-spinner"
							// active={this.state.workInProgress}
							active={this.state.companyNameInProgress}
							activeName="loader--active"
							loaderName="loader"
							spinnerName="loader__spinner"
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<label className="form__label" htmlFor="companyNumber">Company Number</label>
						<input
							className="form__input"
							type="text"
							name="companyNumber"
							value={this.state.companyNumber}
							onChange={this.handleChange}
							placeholder="Enter Company Number"
						/>
						<Loader
							className="form__input-spinner"
							// active={this.state.workInProgress}
							active={this.state.companyNumberInProgress}
							activeName="loader--active"
							loaderName="loader"
							spinnerName="loader__spinner"
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<input
							type="submit"
							className="button button--wide"
							disabled={this.state.workInProgress}
							value={this.getButtonText()}
						/>
					</fieldset>

					<fieldset className="form__fieldset">
						<div className="form__message form__message--error">
							{error ? error : <br />}
						</div>
					</fieldset>
				</form>
			</Element>
		);
	}
}

export default BusinessSearchForm;