import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Headline from '../Headline/Headline';
import '../ServicePacks/style/ServicePacks.css';

export default class OrderSummary extends Component {
	static propTypes = {
		componentType: PropTypes.string,
		currency: PropTypes.string,
		locale: PropTypes.string
	};

	static defaultProps = {
		componentType: 'div',
		currency: 'GBP',
		locale: 'en-GB'
	};

	constructor(props) {
		super(props);

		this.state = {
			summaryItems: {
				packSelected: this.props.packSelected,
				promoSelected: this.props.promoSelected,
				servicesData: this.props.servicesData,
				subscriptionsData: this.props.subscriptionsData,
				addonData: this.props.addonData,
			},
			totalExclTax: this.props.totalOrderExclTax,
			totalInclTax: this.props.totalOrderInclTax,
			totalTax: (this.props.totalOrderInclTax - this.props.totalOrderExclTax),
			totalCredits: this.props.numberOfCredits,
			freeCredits: this.props.freeCredits,
			shortDesc: '',
			termsAccepted: false,
			isAddressEdited: this.props.isAddressEdited,
			dbs: this.props.dbs,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		const {
			summaryItems,
		} = this.state;

		let {
			shortDesc,
		} = this.state;

		// Construct a short description for Services, Subscriptions, AddOns
		const prefixSeparator = shortDesc ? ', ' : '';

		summaryItems.servicesData.forEach(item => shortDesc = shortDesc + prefixSeparator + 'Service: ' + item.Name);
		summaryItems.subscriptionsData.forEach(item => shortDesc = shortDesc + prefixSeparator + ' Subscriptions: ' + item.Name);
		summaryItems.addonData.forEach(item => shortDesc = shortDesc + prefixSeparator + ' Add Ons: ' + item.Name);

		shortDesc += summaryItems.promoSelected ? prefixSeparator + ' Promotions: ' + summaryItems.promoSelected.Name : '';

		/*
		if (summaryItems.subscriptionSelected.Name === 'Pay As You go with 20 free starter credits' &&
			//		if (summaryItems.subscriptionSelected.Type === 'Bronze' &&
			summaryItems.promoSelected &&
			summaryItems.promoSelected.WaiveRegistrationFee === true
		) {
			totalExclTax -= summaryItems.subscriptionSelected.GBP.Price;
			totalTax -= summaryItems.subscriptionSelected.GBP.Tax;


			const modifyPromotion = {
				...summaryItems.promoSelected,
				VAT: true,
				GBP: {
					...summaryItems.promoSelected.GBP,
					Price: summaryItems.subscriptionSelected.GBP.Price * -1,
					Tax: summaryItems.subscriptionSelected.GBP.Tax * -1
				}
			}

			this.setState({
				summaryItems: {
					...summaryItems,
					promoSelected: modifyPromotion
				}
			});
		}
		*/


		this.setState({
			shortDesc: shortDesc,
		});

	}

	handleSummaryConfirm = () => {
		const {
			summaryItems,
			totalCredits,
			freeCredits,
			totalExclTax,
			totalInclTax,
			totalTax,
			shortDesc,
		} = this.state;

		// console.log(
		// 	totalCredits,
		// 	totalInclTax,
		// 	totalExclTax,
		// 	totalTax,
		// 	summaryItems.subscriptionsData,
		// 	summaryItems.servicesData,
		// );

		// send summaryItems.promoSelected as argument to handleSummaryConfirm below

		//totalInclTax totalNet
		//totalExclTax orderTotal

		this.props.handleSummaryConfirm(
			summaryItems.promoSelected,
			summaryItems.subscriptionsData,
			summaryItems.servicesData,
			totalCredits,
			parseFloat(totalInclTax.toFixed(2)),
			parseFloat(totalExclTax.toFixed(2)),
			parseFloat(totalTax.toFixed(2)),
			shortDesc);
	};

	handleCheckboxChange = event => {
		const value = event.target.checked;

		this.setState({
			termsAccepted: value
		});
	};

	renderPrice = price => {
		const {
			currency,
			locale
		} = this.props;

		return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price);
	};

	renderCreditsTotal = () => {

		const {
			totalCredits,
			freeCredits,
		} = this.state;

		// if(totalCredits >= freeCredits) {
		// 	return (totalCredits - freeCredits);
		// } else {
		// 	return freeCredits;
		// }
		return (totalCredits + freeCredits);
	}

	renderTotal = () => {

		return (
			<tr key="totalExclTax">
				<td className="service-table__column service-table__column--mobile-break">
					<span className="service-table__cost">Total:</span>
				</td>
				<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
					{this.renderCreditsTotal()}
				</td>
				<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
					{null}
				</td>
				<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
					{this.renderPrice(this.state.totalExclTax)}
				</td>
				<td className="service-table__column service-table__column--small service-table__column--mobile-break">
					<span className="service-table__cost service-table__cost--summary"><small>{this.renderPrice(this.state.totalTax)}</small></span>
				</td>
				<td className="service-table__column service-table__column--mobile-break service-table__column--right">
					<span className="service-table__cost">{this.renderPrice(this.state.totalInclTax)}</span>
				</td>
			</tr>
		);
	};

	deleteData = (val) => {
		console.log(val);
	}

	renderSummaryItems = () => {
		const {
			summaryItems
		} = this.state;

		const element = (
			<>
				{summaryItems.servicesData.map((item, index) => (
					<tr key={`pack-${index}`}>
						<td className="service-table__column service-table__column--mobile-break">
							<span className="service-table__description">Credit Pack</span>
							<span className="service-table__title">{item.Name}</span>
							<span className="service-table__description"><strong>{`${item.NumberOfUnits} Applicant(s) @ ${item.StandardUnitPriceCredits} Credits per Applicant`}</strong></span>
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{item.Credits}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{this.renderPrice(item.CreditPrice)}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{this.renderPrice(item.PriceExclTax)}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break">
							<span className="service-table__cost service-table__cost--summary"><small>{this.renderPrice((item.PriceInclTax - item.PriceExclTax))}</small></span>
						</td>
						<td className="service-table__column service-table__column--mobile-break service-table__column--right">
							{/*<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">*/}
							<span className="service-table__cost service-table__cost--summary">
								{this.renderPrice(item.PriceInclTax)}
							</span>
						</td>

						{/* <td>
							<button onClick={() => deleteData(val)}>Delete</button>
						</td> */}

					</tr>
				))}

				{summaryItems.subscriptionsData.map((item, index) => (

					<tr key={`subscription-${index}`}>
						<td className="service-table__column service-table__column--mobile-break">
							<span className="service-table__description">Subscription Type</span>
							<span className="service-table__title">{item.Name}</span>
							<span className="service-table__description">
								<strong>
									{item.FreeUnits ? `Free ${item.FreeUnits} User Licence` : `${item.NumberOfUnits} User Licence @ ${this.renderPrice(item.PriceExclTax / item.NumberOfUnits)} per unit`}
								</strong>
							</span>
							<span className="service-table__description">
								<strong>
									{item.isAnnual && item.TrialPeriodTimeUnits > 0 ? `${item.TrialPeriodTimeUnits} month trial per unit` : null}
								</strong>
							</span>
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{item.Credits > 0 ? item.Credits : null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{this.renderPrice(item.PriceExclTax)}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break">
							<span className="service-table__cost service-table__cost--summary"><small>{this.renderPrice((item.PriceInclTax - item.PriceExclTax))}</small></span>
						</td>
						<td className="service-table__column service-table__column--mobile-break service-table__column--right">
							{/* <td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right"> */}
							<span className="service-table__cost service-table__cost--summary">
								{this.renderPrice(item.PriceInclTax)}
							</span>
						</td>
					</tr>))}

				{summaryItems.addonData.map((item, index) => (

					<tr key={`addon-${index}`}>
						<td className="service-table__column service-table__column--mobile-break">
							<span className="service-table__description">Add Ons</span>
							<span className="service-table__title">{item.Name}</span>
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{this.renderPrice(item.PriceExclTax)}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break">
							{<span className="service-table__cost service-table__cost--summary"><small>{this.renderPrice((item.PriceInclTax - item.PriceExclTax))}</small></span>}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							<span className="service-table__cost service-table__cost--summary">
								{this.renderPrice(item.PriceInclTax)}
							</span>
						</td>
					</tr>))}

				{summaryItems.promoSelected
					?
					<tr key="promotion">
						<td className="service-table__column service-table__column--mobile-break">
							<span className="service-table__description">{summaryItems.promoSelected.Name}</span>
							<span className="service-table__title">{summaryItems.promoSelected.Description}</span>
							<span className="service-table__description">
								<strong>
									{null}
								</strong>
							</span>
							<span className="service-table__description">
								<strong>
									{summaryItems.promoSelected.TrialPeriodTimeUnits > 0 ? `${summaryItems.promoSelected.TrialPeriodTimeUnits} month trial per unit` : null}
								</strong>
							</span>
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{summaryItems.promoSelected.Credits > 0 ? summaryItems.promoSelected.Credits : null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right">
							{null}
						</td>
						<td className="service-table__column service-table__column--small service-table__column--mobile-break">
							<span className="service-table__cost service-table__cost--summary"><small>{null}</small></span>
						</td>
						<td className="service-table__column service-table__column--mobile-break service-table__column--right">
							{/* <td className="service-table__column service-table__column--small service-table__column--mobile-break service-table__column--right"> */}
							<span className="service-table__cost service-table__cost--summary">
								{null}
							</span>
						</td>
					</tr>
					: null
				}
			</>
		);
		return element;

	};

	render() {
		const {
			summaryItems
		} = this.state;

		let title = 'Service Pack';
		let description = summaryItems.packSelected.ServicePackName;

		return (
			<>
			<div className="card card--order">
				<br />
				<Headline componentType="h2" type="page">
					Order Summary
				</Headline>

				<Headline componentType="h3">
					Annual Service Quotation
				</Headline>

				<div className="section">
					<table className="service-table">
						<thead>
							<tr>
								<td className="service-table__column service-table__column--medium">Product</td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">No. Of Credits</td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">Credit Unit Price</td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">Item Price<small>(excl. VAT)</small></td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">VAT Rate</td>
								<td className="service-table__column service-table__column--small service-table__column--mobile-break">Item Subtotal</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="service-table__column service-table__column--mobile-break">
									<span className="service-table__description">{title}</span>
									<span className="service-table__title">{description}</span>
								</td>
							</tr>
							{this.renderSummaryItems()}

							{this.renderTotal()}
						</tbody>
					</table>

					{this.state.dbs ? <div>
						If you have selected DBS checks please note that the price indicated does not include the DBS fees of £18 for each standard check and £38 for each enhanced check.
					</div> : null }
					
					</div>
					</div>

					<br />

					<div>
					<form className="form">
						<fieldset className="form__fieldset">
							<label>
								To start using Spotlite today,  please accept our Terms and Conditions. <br /> <br />
								<input
									className="form__input form__input--checkbox"
									type="checkbox"
									checked={this.state.termsAccepted}
									onChange={this.handleCheckboxChange}
								/>

								I accept the <a href="https://spotlite.services/terms/" rel="noopener noreferrer" target="_blank">Terms & Conditions</a>
							</label>
						</fieldset>
					</form>

					<button
						className="button button--negative"
						type="button"
						onClick={() => this.props.handleSummaryCancel(summaryItems.subscriptionsData)}>
						Go back
					</button>

					<button
						className="button"
						type="button"
						onClick={this.handleSummaryConfirm}
						disabled={!this.state.termsAccepted}>
						Accept Quote
					</button>
				</div>
			
			</>
		);
	}
}
