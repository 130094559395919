module.exports = {
    development: {
        // PMD 02/09/21 Remove PORT and add /home for use by API Gateway
        // PORT: 4090,
        // URL: 'https://api.spotlite.services/sh/clienthub',
        // URL: 'https://app-dev-clienthub.azurewebsites.net/home',
        URL: 'http://localhost:4090/home',
        // VERIFYURL: 'http://localhost:4094/home',
        VERIFYURL: process.env.REACT_APP_DEV_VERIFYURL,
        BIZIQURL: process.env.REACT_APP_DEV_BIZIQURL,
        BIZIQPORT: process.env.REACT_APP_DEV_BIZIQPORT,
        GTMID: 'GTM-WXMN3BG',
        GTMAUTH: 'fT8WY9JDQm2KmyOfBpeO3w',
        GTMPREVIEW: 'env-6',
    },
    test: {
        // PMD 02/09/21 Remove PORT and add /home for use by API Gateway
        // PORT: 4091,
        // URL: 'http://localhost:',
        URL: 'http://localhost:4091/home',
        VERIFYURL: 'https://css02-dev-spotlite-verification.azurewebsites.net:443',
        BIZIQURL: 'http://localhost:',
        BIZIQPORT: 4097,
        GTMID: 'GTM-WXMN3BG',
        GTMAUTH: 'fT8WY9JDQm2KmyOfBpeO3w',
        GTMPREVIEW: 'env-6'
    },
    sandbox: {
        // PMD 02/09/21 Remove PORT and /home for use by API Gateway
        // PORT: 443,
        URL: process.env.REACT_APP_PROD_URL,
        VERIFYURL: process.env.REACT_APP_PROD_VERIFYURL,
        // BIZIQURL: 'https://cmsprod01-portal-api.azurewebsites.net:',
        BIZIQURL: 'https://cmsprod01-spotlite-biziq.azurewebsites.net:',
        BIZIQPORT: process.env.REACT_APP_PROD_BIZIQPORT,
        GTMID: 'GTM-WXMN3BG',
        GTMAUTH: 'adkvvsI7Zi564lmXn08w4w',
        GTMPREVIEW: 'env-2',
    },
    production: {
        // PMD 02/09/21 Remove PORT and /home for use by API Gateway
        // PORT: 443,
        URL: process.env.REACT_APP_PROD_URL,
        VERIFYURL: process.env.REACT_APP_PROD_VERIFYURL,
        // BIZIQURL: 'https://cmsprod01-portal-api.azurewebsites.net:',
        BIZIQURL: 'https://cmsprod01-spotlite-biziq.azurewebsites.net:',
        BIZIQPORT: process.env.REACT_APP_PROD_BIZIQPORT,
        GTMID: 'GTM-WXMN3BG',
        GTMAUTH: 'adkvvsI7Zi564lmXn08w4w',
        GTMPREVIEW: 'env-2',
    }
    /*
    THIS IS THE CONFIG FOR cmsprod01-registration-portal
    production: {
        PORT: 443,
        URL: 'https://cmsprod01-portal-api.azurewebsites.net:',
        // URL: 'https://spotliteregistrationapi.azurewebsites.net:',
        GTMID: 'GTM-WXMN3BG',
        GTMAUTH: 'adkvvsI7Zi564lmXn08w4w',
        GTMPREVIEW: 'env-2'
    }
    */
}