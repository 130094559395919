import fetch from 'isomorphic-fetch';

export function apiParse(data) {
  // console.log('apiParse - Data::', data);
  if (data instanceof Error) {
    throw data;
  }
  switch (data.status) {
    case "ok":
      return data.result;
    case "error":
      throw new Error(data.error || 'Unknown Error');
    default:
      throw new Error('Unknown Status:', data);
  }
}

export function postRequest(URI, options, credentials, headers = {}) {
  return fetch(URI, {
    method: 'POST',
    credentials: credentials,
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(options)
  })
    .then(function (response) {
      if (response.status === 401) {
        throw new Error("Error: This service requires authentication");
      }
      return response.json();
    })
    .catch(err => err)
}

export function getRequest(URI, credentials, headers = {}) {
  return fetch(URI, {
    method: 'GET',
    Accept: 'application.json',
    credentials: credentials,
    headers: {
      ...headers
    },
  })
    .then(function (response) {
      if (response.status === 401) {
        throw new Error("Error: This service requires authentication");
      }
      return response.json();
    })
    .catch(err => err)
}
